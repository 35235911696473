import { ROLES } from "routes"

export interface AuthInitialState {
  isLoggedIn: boolean | null
  error: string | null
  role: ROLES
  loading: boolean
}

export const authInitialStates: AuthInitialState = {
  isLoggedIn: null,
  error: null,
  role: ROLES.admin,
  loading: false,
}

export default authInitialStates
