import React from "react"
import MUITooltip, { TooltipProps } from "@mui/material/Tooltip"

interface Props extends TooltipProps {
  title: any
}

const Tooltip = ({ children, title, ...props }: Props) => {
  return (
    <MUITooltip
      placement="right"
      PopperProps={{
        className: "ls-tooltip",
      }}
      arrow
      // @ts-ignore
      title={title}
      {...props}>
      {children}
    </MUITooltip>
  )
}

export default Tooltip
