import {cssTransition, toast, ToastOptions} from "react-toastify"
import i18n from "~/localization/index"
import {WarningIcon} from "~/assets/svg";

export default function showError(err: any, options?: ToastOptions) {
  const t = i18n.t
  const fromTop = cssTransition({
    enter: "easeIn",
    exit: "easeOut"
  })
  const _options = {
    autoClose: 2500,
    icon: WarningIcon({fill: "#FF674F"}),
    // transition: fromTop,
    ...options,
  }
  if (typeof err === "string") {
    toast.error(err, _options)
  } else {
    if (err?.response?.data?.data?.code) {
      toast.error(`${t(`error_codes.${err.response.data.data.code}`)}`, _options)
    } else {
      toast.error(
        `${err?.response?.status ? `Error ${err.response?.status}` : ""} ${
          err?.response?.data?.message || err?.message || err
        }`,
        _options,
      )
    }
  }
}
