import envs from "~/config/env";
import { default as axiosLib } from "axios";

let headers = {};
const axios = axiosLib.create({
  baseURL: envs.BACKEND_URL,
  headers,
});

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("@token");
    if (token) {
      if (config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axios;
