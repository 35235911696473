import classNames from "classnames"
import InlineBox, { BoxProps } from "../InlineBox"
import { Property } from "csstype"

export interface IRowProps extends BoxProps<HTMLDivElement> {
  justify?: Property.JustifyContent | undefined
  align?: Property.AlignItems | undefined
}

const Row = ({ justify, align, ...props }: IRowProps) => {
  return (
    // @ts-ignore
    <InlineBox
      display="flex"
      alignItems={align}
      justifyContent={justify}
      className={classNames(props.className)}
      {...props}>
      {props.children}
    </InlineBox>
  )
}

export default Row
