export const en = {
  name: "en",
  options: {
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    toolbar: {
      exportToSVG: "Download SVG",
      exportToCSV: "Download CSV",
      exportToPNG: "Download PNG",
      selection: "Selection",
      selectionZoom: "Selection Zoom",
      zoomIn: "Zoom In",
      zoomOut: "Zoom Out",
      pan: "Panning",
      reset: "Reset Zoom",
    },
  },
}

export const ru = {
  name: "ru",
  options: {
    months: [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ],
    shortMonths: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
    days: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
    shortDays: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
    toolbar: {
      exportToSVG: "Скачать SVG",
      exportToCSV: "Скачать CSV",
      exportToPNG: "Скачать PNG",
      selection: "Выделить",
      selectionZoom: "Выбор масштаба",
      zoomIn: "Увеличить",
      zoomOut: "Уменьшить",
      pan: "Panning",
      reset: "Сбросить масштаб",
    },
  },
}

export const uz = {
  name: "uz",
  options: {
    months: [
      "Yanvar",
      "Fevral",
      "Mart",
      "Aprel",
      "May",
      "Iyun",
      "Iyul",
      "Avgust",
      "Sentyabr",
      "Oktyabr",
      "Noyabr",
      "Dekabr",
    ],
    shortMonths: ["Yan", "Fev", "Mart", "Apr", "May", "Iyun", "Iyul", "Avg", "Sen", "Okt", "Noy", "Dek"],
    days: ["Yakshanba", "Dushanba", "Seshanba", "Chorshanba", "Payshanba", "Juma", "Shanba"],
    shortDays: ["Ya", "Du", "Se", "Ch", "Pb", "Ju", "Sh"],
    toolbar: {
      exportToSVG: "SVG formatda yuklab olish",
      exportToCSV: "CSV formatda yuklab olish",
      exportToPNG: "PNG formatda yuklab olish",
      selection: "Ajratish",
      selectionZoom: "Zoom tanlash",
      zoomIn: "Kattalashtirish",
      zoomOut: "Kamaytirish",
      pan: "Panning",
      reset: "Masshtabni tiklash",
    },
  },
}
