import React from "react"
import { Link, useLocation } from "react-router-dom"
import classNames from "classnames"

import { COLORS } from "~/constants/theme"
import { useTranslation } from "react-i18next"
import InlineBox from "../common/InlineBox"
import { DRAWER_ROUTES } from "routes"
import useAppSelector from "~/hooks/useAppSelector"
import { ProIcon } from "~/assets/svg"
import styled from "styled-components"
import Tooltip from "../common/Tooltip"

export const Nav = styled.nav`
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9;
  display: flex;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  background-color: var(--white);

  @media (max-width: 767px) {
    & .overlay {
      z-index: -1;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.43);
    }
    visibility: hidden;
    opacity: 0;
    &.show {
      visibility: visible;
      opacity: 1;
    }
  }
`

export const DrawerList = styled("div")({
  flex: 1,
  backgroundColor: "var(--white)",
  marginTop: 60,
})

export const DrawerListItem = styled("div")({
  position: "relative",
  backgroundColor: "var(--white)",

  "&.active": {
    backgroundColor: COLORS.backgroundOnHover,
    boxShadow: "inset 5px 0px 0px var(--primary)",
  },
})

export const DrawerListItemLink = styled(Link)({
  width: "100%",
  fontWeight: "500",
  padding: "16px 20px",
  display: "inline-block",
  color: "var(--primary-buttons-dark)",
  fontFamily: "var(--font)",
})

const List = () => {
  const { currentPlan } = useAppSelector((state) => state.premium)
  const role = useAppSelector((state) => state.auth.role)
  const params = useLocation()
  const { i18n } = useTranslation() as any

  const currentUrl = params.pathname.split("/")[1]

  return (
    <DrawerList>
      {DRAWER_ROUTES.map((route, index) => {
        const title = i18n.t(route.name)
        if (route.pro && currentPlan === "pro") {
          return null
        }

        if (route.permission.includes(role)) {
          return (
            <Tooltip key={`drawer_item_${index}`} placement="right" title={title}>
              <DrawerListItem
                style={{
                  marginBottom: DRAWER_ROUTES.length - 1 === index ? 56 : 0,
                }}
                className={classNames({
                  active: currentUrl === route.path.slice(1),
                })}>
                <DrawerListItemLink to={route.path}>
                  <InlineBox
                    position="relative"
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                    {route.icon}

                    {route.pro ? (
                      <InlineBox position="absolute" t={"-8px"} r={"-15px"}>
                        <ProIcon width="25" height="10" />
                      </InlineBox>
                    ) : null}
                  </InlineBox>
                </DrawerListItemLink>
              </DrawerListItem>
            </Tooltip>
          )
        }
      })}
    </DrawerList>
  )
}

const Drawer = ({
  isDrawerOpen,
  setIsDrawerOpen,
}: {
  isDrawerOpen: boolean
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <Nav className={classNames(isDrawerOpen ? "show" : "hide")}>
      <List />

      <div className="overlay" onClick={() => setIsDrawerOpen(false)} />
    </Nav>
  )
}

export default React.memo(Drawer)
