import { Grid } from "@mui/material"
import LineChart from "./LineChart"
import DonutChart from "./DonutChart"
import PieChart from "./PieChart"
import { H3 } from "~/components/common/Headings"
import { useTranslation } from "react-i18next"
import InlineBox from "~/components/common/InlineBox"

interface ChartsProps {
  tgCount?: number
  whCount: number
  regsSales: any[]
  statuses: {
    active: number
    completed: number
    dropouts: 0
  }
}

const Charts = ({ tgCount, whCount, statuses, regsSales }: ChartsProps) => {
  const { t } = useTranslation() as any

  return (
    <Grid container spacing={2} className="main-chart">
      <Grid item xs={12} md={12} lg={6}>
        <div className={"item"}>
          <InlineBox p="37px" pb="5px">
            <H3>{t("regs_and_sales_per", { period: t("year").toLowerCase() })}</H3>
          </InlineBox>
          <InlineBox flex={1} px={"37px"}>
            <LineChart data={regsSales} />
          </InlineBox>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <div className={"item"}>
          <InlineBox p="37px" pb="5px">
            <H3>{t("complete_percentage")}</H3>
          </InlineBox>
          <InlineBox flex={1}>
            <DonutChart
              active={statuses.active}
              dropouts={statuses.dropouts}
              completed={statuses.completed}
            />
          </InlineBox>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <div className={"item"}>
          <InlineBox p="37px" pb="5px">
            <H3>{t("messenger")}</H3>
          </InlineBox>
          <InlineBox flex={1}>
            <PieChart data={[tgCount, whCount]} />
          </InlineBox>
        </div>
      </Grid>
    </Grid>
  )
}

export default Charts
