import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import {
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { SubmitHandler, useForm } from "react-hook-form"
import classNames from "classnames"

import { CLASSROOM_CREATE, SIGN_IN } from "~/constants/routes"
import { H2 } from "~/components/common/Headings"
import { P3, P5 } from "~/components/common/Paragraph"
import {
  CheckboxCheckedIcon,
  EyeCloseIcon,
  EyeOpenIcon,
  LogoIcon,
  RectangleIcon,
} from "~/assets/svg"
import { TextField } from "~/components/common/TextField"
import { authenticate } from "~/context/slices/auth"
import { setUser } from "~/context/slices/user"
import { setAutoRenewal, setCurrentPlan } from "~/context/slices/premium"
import { EMAIL_PATTERN } from "~/utils/validation/validateEmail"
import useAppSelector from "~/hooks/useAppSelector"
import useAppDispatch from "~/hooks/useAppDispatch"
import Row from "~/components/common/Row"
import AUTH_API from "~/api/auth"
import Button from "~/components/common/Button"
import Loader from "~/components/Loader"
import InlineBox from "~/components/common/InlineBox"
import signUp from "~/context/reducers/auth/signUp"

import img_ru from "~/assets/imgs/login_ru.jpg"
import img_en from "~/assets/imgs/login_en.jpg"
import img_uz from "~/assets/imgs/login_uz.jpg"
import showError from "~/utils/toast/showError"
import { Footer, Logo } from "../SignIn"

type Inputs = {
  email: string
  password: string
  password_confirmation: string
  agree_with_terms: boolean
}

const SignUp = () => {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<Inputs>()
  const redirectUri = window.location.origin + SIGN_IN
  const navigate = useNavigate()
  const auth = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()
  const location = useLocation()

  const [isPasswordVisible, setIsPasswordVisible] = useState(true)

  const toggleVisibilityPassword = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }

  const {
    t,
    i18n: { language },
  } = useTranslation() as any

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (data.password !== data.password_confirmation) {
      setError("password", {
        type: "value",
        message: t("error_messages.passwords_not_equal"),
      })
      setError("password_confirmation", {
        type: "value",
        message: t("error_messages.passwords_not_equal"),
      })
      return
    }
    const res = await dispatch(
      signUp({
        email: data.email.toLowerCase(),
        password: data.password.trim(),
      }),
    )
    localStorage.setItem("@email", data.email)

    if (res.meta.requestStatus === "fulfilled") {
      navigate("/otp", {
        state: res.payload,
        replace: true,
      })
    } else if (res.meta.requestStatus === "rejected") {
      if (res.payload.status === 423) {
        const duplicateProp = res.payload.message.split(" | ")[1]
        if (duplicateProp === "email") {
          showError(t("error_messages.existing_email"), {
            position: "top-center",
          })
        }
      } else {
        showError(res.payload, {
          position: "top-center",
        })
      }
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    if (!queryParams.get("code")) return
    const code = queryParams.get("code")
    const scope = queryParams.get("scope")
    const authuser = queryParams.get("authuser")

    if (code && scope && authuser) {
      AUTH_API.googleOAuth(code, redirectUri)
        .then((res) => {
          if (res.data.token) {
            if (res.data.me?.email && res.data.me?.domain && res.data.me?.name) {
              dispatch(setUser(res.data.me))
              dispatch(authenticate({ token: res.data.token, me: res.data.me }))

              if (res.data.me.proPlan?.createdAt) {
                const now = Date.now()
                const createdAt = new Date(res.data.me.proPlan?.createdAt).getTime()
                const expiresIn = new Date(res.data.me.proPlan?.expiresIn).getTime()

                if (now > createdAt && now < expiresIn) {
                  dispatch(setCurrentPlan("pro"))
                  dispatch(setAutoRenewal(res.data.me.proPlan?.autoRenewal))
                }
              }
            }
            localStorage.setItem("@email", res.data.me.email)
            localStorage.setItem("@token", res.data.token)

            navigate(CLASSROOM_CREATE, {
              replace: true,
            })
          }
        })
        .catch((err) => {
          showError(err)
          navigate(SIGN_IN, {
            replace: true,
          })
        })
    } else {
      navigate(SIGN_IN, {
        replace: true,
      })
    }
  }, [location])

  if (new URLSearchParams(window.location.search).get("code")) {
    return <Loader />
  }

  return (
    <Grid className={"ls-auth__main"} container>
      <Grid
        xs={12}
        sm={7}
        md={6}
        item
        className={classNames("ls-auth__leftContainer")}>
        <Logo>
          <LogoIcon />
        </Logo>

        <InlineBox w="350px" maxWidth="95%" mb="20px">
          <H2 textAlign="center" mb="20px">
            {t("sign_up")}
          </H2>
          <P3 textAlign="center" mb="30px">
            {t("sign_up_subtitle")}
          </P3>
          <form onSubmit={handleSubmit(onSubmit)} className="20px">
            <InlineBox mb="20px">
              <TextField
                variant="outlined"
                label={<>{t("email", { count: 1 })}</>}
                error={!!errors.email?.type}
                id="email"
                type="email"
                {...register("email", {
                  required: true,
                  pattern: EMAIL_PATTERN,
                })}
                fullWidth
                placeholder="name@example.com"
              />
            </InlineBox>
            <InlineBox mb="20px">
              <TextField
                variant="outlined"
                label={<>{t("password")}</>}
                error={!!errors.password?.type}
                helperText={errors.password?.message}
                id="password"
                type={isPasswordVisible ? "password" : "text"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleVisibilityPassword}>
                        {isPasswordVisible ? (
                          <EyeOpenIcon
                            width="20"
                            height="15"
                            fill="var(--new-grey)"
                          />
                        ) : (
                          <EyeCloseIcon
                            width="20"
                            height="15"
                            fill="var(--new-grey)"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register("password", {
                  required: true,
                  minLength: 8,
                })}
                autoComplete="off"
                fullWidth
                placeholder="******"
              />
            </InlineBox>
            <InlineBox mb="20px">
              <TextField
                variant="outlined"
                label={<>{t("password_confirmation")}</>}
                error={!!errors.password_confirmation?.type}
                helperText={errors.password?.message}
                id="password_confirm"
                type={isPasswordVisible ? "password" : "text"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleVisibilityPassword}>
                        {isPasswordVisible ? (
                          <EyeOpenIcon
                            width="20"
                            height="15"
                            fill="var(--new-grey)"
                          />
                        ) : (
                          <EyeCloseIcon
                            width="20"
                            height="15"
                            fill="var(--new-grey)"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                {...register("password_confirmation", {
                  required: true,
                  minLength: 8,
                })}
                autoComplete="off"
                placeholder="******"
              />
            </InlineBox>
            <Row align="center" mb="30px">
              <Checkbox
                {...register("agree_with_terms", {
                  required: true,
                })}
                className={classNames({
                  "Mui-error": errors.agree_with_terms,
                })}
                id="agree_terms"
                icon={<RectangleIcon />}
                checkedIcon={<CheckboxCheckedIcon />}
              />
              <label htmlFor="agree_terms">
                <P3 ml="10px">
                  <Trans
                    i18nKey={"agree_to_terms"}
                    components={[
                      <a
                        href="https://lessenger.uz/public-offer"
                        target="_blank"
                        rel="noreferrer"></a>,
                    ]}
                  />
                </P3>
              </label>
            </Row>

            <Button className="w-100 brsr-extra" type="submit">
              <>
                {auth.loading ? (
                  <CircularProgress
                    size={20}
                    sx={{
                      color: "var(--white)",
                    }}
                  />
                ) : (
                  t("submit")
                )}
              </>
            </Button>
          </form>
        </InlineBox>
        <Footer>
          <P3 textAlign="center" mb="25px">
            {t("have_account")}?{" "}
            <Link to={SIGN_IN}>
              <>{t("login")}</>
            </Link>
          </P3>
          <P5 textAlign="center">
            © 2022{" "}
            <strong>
              <Link to="/">Lessenger</Link>
            </strong>{" "}
            {t("all_rights_reserved")}
          </P5>
        </Footer>
      </Grid>
      <Grid
        xs={12}
        sm={5}
        md={6}
        className={"ls-auth__rightContainer"}
        sx={{
          backgroundImage: `url(${
            language === "en" ? img_en : language === "uz" ? img_uz : img_ru
          })`,
        }}
        item
      />
    </Grid>
  )
}

export default SignUp
