import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import useAppDispatch from "~/hooks/useAppDispatch"
import AUTH_API from "~/api/auth"

import { COLORS } from "~/constants/theme"
import { P5 } from "~/components/common/Paragraph"
import { H2 } from "~/components/common/Headings"
import Button from "~/components/common/Button"
import { Grid } from "@mui/material"
import { LogoIcon } from "~/assets/svg"
import { TextField } from "~/components/common/TextField"
import { authenticate } from "~/context/slices/auth"
import { setUser } from "~/context/slices/user"
import { AxiosError } from "axios"

import img_ru from "~/assets/imgs/login_ru.jpg"
import img_en from "~/assets/imgs/login_en.jpg"
import img_uz from "~/assets/imgs/login_uz.jpg"
import showError from "~/utils/toast/showError"
import validateSubdomain from "~/utils/validation/validateSubdomain"
import InlineBox from "~/components/common/InlineBox"

const Logo = styled.h1`
  margin-top: 50px;
`

const Footer = styled.footer`
  margin-bottom: 30px;
`

export const CustomGroupTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    fieldset {
      border-radius: 8px 0 0 8px;
      border-color: var(--grey-2);
    }
  }
`

const ClassroomCreate = () => {
  const dispatch = useAppDispatch()

  const [classroomTitle, setClassroomTitle] = useState("")
  const [subdomain, setSubdomain] = useState("")
  const {
    t,
    i18n: { language },
  } = useTranslation() as any

  const handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClassroomTitle(e.target.value)
  }
  const handleSubdomain = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (validateSubdomain(e.target.value.toLowerCase())) {
      setSubdomain(e.target.value.toLowerCase())
    }
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const activateToken = localStorage.getItem("@actionToken")
    const email = localStorage.getItem("@email")
    if (classroomTitle && subdomain && activateToken) {
      try {
        if (activateToken === null) {
          // TODO: Handle Error
          return
        }
        const res = await AUTH_API.createStore({
          avatar: undefined,
          name: classroomTitle,
          domain: subdomain,
          activateToken,
        })
        const name = classroomTitle.split(" ")[0]
        const surname = classroomTitle.split(" ")[1] || ""
        if (res.data.token) {
          dispatch(
            setUser({
              id: res.data?.authorId,
              email: email || "",
              domain: subdomain,
              wallets: [],
              name: name,
              avatar: "placeholder.png",
              surname: surname,
              paymentMethods: {},
              intro: "",
              links: undefined,
              rating: 0,
              tagLine: "",
            }),
          )

          dispatch(
            authenticate({
              token: res.data.token,
              me: {
                id: res.data?.author?.id,
                email,
                domain: subdomain,
                name: name,
              },
            }),
          )
        }
      } catch (err: any) {
        if (err.isAxiosError) {
          const error: AxiosError = err
          if (error.response?.status === 423) {
            // @ts-ignore
            const duplicateProp = error.response?.data?.message.split(" | ")[1]
            if (duplicateProp === "domain") {
              showError(t("error_messages.existing_domain"))
            }
          } else {
            showError(err)
          }
        }
      }
    }
  }

  return (
    <Grid className={"ls-auth__main"} container>
      <Grid xs={12} sm={7} md={6} item className={"ls-auth__leftContainer"}>
        <Logo>
          <LogoIcon />
        </Logo>

        <InlineBox w="350px" maxWidth="95%">
          <H2 textAlign="center" mb="20px">
            {t("classroom_create_title")}
          </H2>
          {/* <P3 textAlign="center" mb="30px">
            {t("classroom_create_subtitle")}
          </P3> */}
          <form onSubmit={onSubmit} className="mb-20">
            <div className="mb-20">
              <TextField
                variant="outlined"
                label={t("full_name")}
                id="email"
                fullWidth
                value={classroomTitle}
                autoComplete="off"
                onChange={handleTitle}
                className="w-100"
                placeholder={t("placeholders.full_name")}
              />
            </div>
            <div className="mb-20">
              <InlineBox display="flex">
                <InlineBox flexGrow={1}>
                  <CustomGroupTextField
                    variant="outlined"
                    label={t("subdomain")}
                    id="subdomain"
                    value={subdomain}
                    fullWidth
                    autoComplete="off"
                    className="w-100"
                    onChange={handleSubdomain}
                    placeholder="author"
                  />
                </InlineBox>
                <InlineBox
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  px="14px"
                  py="10px"
                  borderRadius="0 8px 8px 0"
                  backgroundColor={COLORS.backgroundOnHover}
                  border="1px solid var(--grey-2)"
                  borderLeft="none">
                  .lessenger.uz
                </InlineBox>
              </InlineBox>
            </div>
            <Button className={"w-100 brsr-5"} type="submit">
              {t("authorize")}
            </Button>
          </form>
        </InlineBox>

        <Footer>
          <P5>
            © 2022{" "}
            <strong>
              <Link to="#">Lessenger</Link>
            </strong>{" "}
            {t("all_rights_reserved")}
          </P5>
        </Footer>
      </Grid>
      <Grid
        xs={12}
        sm={5}
        md={6}
        className={"ls-auth__rightContainer"}
        item
        sx={{
          backgroundImage: `url(${
            language === "en" ? img_en : language === "uz" ? img_uz : img_ru
          })`,
        }}
      />
    </Grid>
  )
}

export default ClassroomCreate
