import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ICard } from "~/types/payment"
import premiumInitialStates, { PremiumInitialState } from "../initialStates/premium"

const initialState = premiumInitialStates

export const premiumSlice = createSlice({
  name: "premium",
  initialState,
  reducers: {
    setInitialPremium: (state) => {
      state.autoRenewal = premiumInitialStates.autoRenewal
      state.bill = premiumInitialStates.bill
      state.cards = premiumInitialStates.cards
      state.currentPlan = premiumInitialStates.currentPlan
      state.mainWallet = undefined
      state.plan = undefined
      state.status = premiumInitialStates.status
      state.paymentMethod = premiumInitialStates.paymentMethod
    },
    setBillType: (state, action: PayloadAction<PremiumInitialState["bill"]>) => {
      state.bill = action.payload
    },
    setPaymentMethod: (
      state,
      action: PayloadAction<PremiumInitialState["paymentMethod"]>,
    ) => {
      state.paymentMethod = action.payload
    },
    setAutoRenewal: (
      state,
      action: PayloadAction<PremiumInitialState["autoRenewal"]>,
    ) => {
      state.autoRenewal = action.payload
    },

    setAllCards: (state, action: PayloadAction<ICard[]>) => {
      state.cards = action.payload
    },
    setCurrentPlan: (
      state,
      action: PayloadAction<PremiumInitialState["currentPlan"]>,
    ) => {
      state.currentPlan = action.payload
    },
    addCard: (state, action: PayloadAction<ICard>) => {
      const user = JSON.parse(localStorage.getItem("@user") || "{}")

      if (state.cards.length === 0) {
        state.mainWallet = 0
        user.mainWallet = 0
      }
      state.cards.push(action.payload)
      if (user.wallets?.length) {
        user.wallets.push({
          cardTitle: "",
          expire: action.payload.cardExpired,
          number: action.payload.cardNumber,
          recurrent: true,
          token: action.payload.cardToken,
          type: "",
          verify: true,
        })
      } else {
        user.wallets = [
          {
            cardTitle: "",
            expire: action.payload.cardExpired,
            number: action.payload.cardNumber,
            recurrent: true,
            token: action.payload.cardToken,
            type: "",
            verify: true,
          },
        ]
      }

      localStorage.setItem("@user", JSON.stringify(user))
    },
    removeCard: (state, action: PayloadAction<number>) => {
      const user = JSON.parse(localStorage.getItem("@user") || "{}")

      const newCards = state.cards.filter((_, i) => i !== action.payload)
      user.wallets = user.wallets.filter((_: any, i: number) => i !== action.payload)
      if (user.wallets.length === 1) {
        state.mainWallet = 0
      } else {
        state.mainWallet =
          (state.mainWallet || 0) - 1 < 0 ? 0 : (state.mainWallet || 0) - 1
      }
      user.mainWallet = state.mainWallet
      localStorage.setItem("@user", JSON.stringify(user))
      state.cards = newCards
    },

    makeMainCard: (state, action: PayloadAction<number>) => {
      const user = JSON.parse(localStorage.getItem("@user") || "{}")

      user.mainWallet = action.payload
      state.mainWallet = action.payload

      localStorage.setItem("@user", JSON.stringify(user))
    },
  },
})

export const {
  addCard,
  removeCard,
  setCurrentPlan,
  setAllCards,
  makeMainCard,
  setBillType,
  setPaymentMethod,
  setAutoRenewal,
  setInitialPremium,
} = premiumSlice.actions

export default premiumSlice.reducer
