import AUTH_API, { ISignUpRequestBody } from "~/api/auth"
import { createAsyncThunk } from "@reduxjs/toolkit"

interface AppError {
  message: string
  status: number
}

export const signUp = createAsyncThunk<
  any,
  ISignUpRequestBody,
  {
    rejectValue: AppError
  }
>("auth/signUp", async (body, thunkAPI) => {
  try {
    const response = await AUTH_API.signUp(body)

    return response.data
  } catch (err: any) {
    return thunkAPI.rejectWithValue({
      message: err?.response?.data?.message || err?.message || "SignUp error",
      status: err?.response?.status || 400,
    })
  }
})

export default signUp
