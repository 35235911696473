const DEV_BACKEND_URL = "http://10.10.10.117:6040"
const PROD_BACKEND_URL = "https://api.lessenger.uz"
const FILE_SERVER_URL = "https://api.lessenger.uz"

const devEnvironmentVariables = {
  BACKEND_URL: DEV_BACKEND_URL,
  FILE_SERVER_URL: FILE_SERVER_URL,
}

const prodEnvironmentVariables = {
  BACKEND_URL: PROD_BACKEND_URL,
  FILE_SERVER_URL: FILE_SERVER_URL,
}

export const isDev = process.env.NODE_ENV === "dev"

const envs = isDev ? devEnvironmentVariables : prodEnvironmentVariables

export default envs
