import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Chart from "react-apexcharts"

import { SHORT_MONTHS } from "~/constants/months"
import { en, ru, uz } from "./localization"

const LineChart = ({ data }: { data: any[] }) => {
  const { t, i18n } = useTranslation() as any
  const [options, setOptions] = useState<ApexCharts.ApexOptions>({
    chart: {
      type: "line",
      toolbar: {
        tools: {
          download: false,
        },
      },
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      id: "line-chart",
      locales: [en, ru, uz],
      defaultLocale: i18n.language.split(/-|_/)[0] || "ru",
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0)
        },
        style: {
          fontFamily: "var(--font)",
        },
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          fontFamily: "var(--font)",
        },
      },
      tooltip: {
        enabled: false,
      },
      categories: [],
    },
    colors: ["#3D8AFF", "#00CD88", "#D5DE58"],
    stroke: {
      // curve: "smooth",
    },
    dataLabels: {
      style: {
        fontFamily: "var(--font)",
      },
    },
    legend: {
      fontFamily: "var(--font)",
      fontSize: "14px",
      offsetY: 10,
      fontWeight: "700",
    },
    tooltip: {
      style: {
        fontFamily: "var(--font)",
      },
      x: {
        formatter: (time, opts) => {
          const date: Date = new Date(time)
          return `${date.getDate()} ${t(
            `full_months.${SHORT_MONTHS[date.getMonth()]}`,
          )}`
        },
      },
    },
  })
  const [series, setSeries] = useState<{ name: string; data: number[] }[]>([
    // {
    //   name: t("views"),
    //   data: [],
    // },
    {
      name: t("registrations"),
      data: [],
    },
    {
      name: t("sales"),
      data: [],
    },
  ])

  const transformData = () => {
    let newSeries = [...series]
    let newOptions = { ...options }

    let date = data.map((data) => {
      return new Date(data._id).toISOString()
    })
    // let views: number[] = data.map((data) => {
    //   return data.views || 0
    // })
    let regs: number[] = data.map((data) => {
      return data.registrations || 0
    })
    let sales: number[] = data.map((data) => {
      return data.sales || 0
    })

    // newSeries[0].data = views
    newSeries[0].data = regs
    newSeries[1].data = sales
    if (newOptions.xaxis) {
      setOptions({
        ...options,
        xaxis: {
          ...options.xaxis,
          categories: date,
        },
      })
    }

    setSeries(newSeries)
  }

  useEffect(() => {
    transformData()

    return () => {}
  }, [data])

  return (
    <div id="lineChart" style={{ height: "100%", overflow: "hidden" }}>
      <Chart options={options} series={series} type="line" height={"90%"} />
    </div>
  )
}

export default LineChart
