import { StrictMode } from "react"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { ToastContainer } from "react-toastify"
import { BrowserRouter } from "react-router-dom"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"

import { lightTheme } from "~/constants/theme"
import { store } from "~/context/store"
import ConfirmDialog from "./components/common/ConfirmDialog"
import App from "./App"

import "react-toastify/dist/ReactToastify.css"
import "~/assets/css/bootstrap-reboot.min.css"
import "~/styles/index.scss"
import "./localization"

const container = document.getElementById("root")
if (!container) throw new Error("Failed to find the root element")
const root = createRoot(container)

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 776,
      lg: 992,
      xl: 1200,
    },
  },
})

root.render(
  <StrictMode>
    <ThemeProvider theme={{ ...lightTheme, theme }}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
          <ToastContainer />
          <ConfirmDialog />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </StrictMode>,
)
