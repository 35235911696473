export type INewMediaContent = {
  fileId: string
  fileName: string
}
export type IMediaContent = INewMediaContent | string

export interface IChoice {
  option: string
  media: IMediaContent[]
  response?: string
}

export interface IBody {
  id: string
  text: string
  media: IMediaContent[]
  type: "body"
}

export interface IQuestion {
  id: string
  questionPrompt: string
  media: IMediaContent[]
}

export interface Multiple extends IQuestion {
  type: "multiple"
  choices: IChoice[]
  correctOption?: number
}

export interface OpenEnded extends IQuestion {
  type: "open_ended"
  response: string
  responseMedia: IMediaContent[]
}

export interface YesNo extends IQuestion {
  type: "yes_no"
  yesResp: string
  yesMedia: IMediaContent[]
  noResp: string
  noMedia: IMediaContent[]
}

export type IContent = IBody | Multiple | OpenEnded | YesNo
export type Questions = Multiple | OpenEnded | YesNo

export type SkipDays = "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "Sun"

export interface ILesson {
  subject: string
  content: IContent[]
}

export interface ICourse {
  generatedWithAI: boolean;
  isActiveNow: boolean
  isActiveNowInit: boolean
  id: string
  title: string
  author: string
  category: string
  price: number
  authorLink: boolean
  description: string
  tags: string[]
  logo: string
  priority: number
  skipDays?: SkipDays[]
  sample?: boolean
  forwardOpenAnswers?: boolean
  forwardsChatId?: string
  status:
    | "draft"
    | "published"
    | "deactivated"
    | "inreview"
    | "sample"
    | "hidden"
    | "store"
    | "market"
    | "approved"
    | "rejected"
    | "wfreview"
  lessons: ILesson[]
  activationMessage: string
  lessonsLength: number
  lang: string
  promo?: string | null
}

export interface ICoursePublish {
  logo: ICourse["logo"]
  id: ICourse["id"]
  price: ICourse["price"]
  status: ICourse["status"]
  title: ICourse["title"]
  skipDays: ICourse["skipDays"]
  forwardOpenAnswers: ICourse["forwardOpenAnswers"]
  forwardsChatId: ICourse["forwardsChatId"]
  lang: ICourse["lang"]
  category: ICourse["category"]
  forwardsChatLink?: string
  isActiveNow: boolean
  isActiveNowInit: boolean
}

export interface IInitCourse extends ICourse {
  initDescription: string
  initTags: string[]
  initLessons: ILesson[]
  initActivationMessage: string
}

export const ILessonDefault: ILesson = {
  content: [],
  subject: "",
}

export const ICourseDefault: ICourse = {
  id: "",
  title: "",
  author: "",
  lessonsLength: 0,
  status: "draft",
  tags: [],
  price: 0,
  category: "",
  authorLink: false,
  description: "",
  logo: "",
  lessons: [
    {
      subject: "",
      content: [
        {
          type: "body",
          id: "body_0",
          media: [],
          text: "",
        },
      ],
    },
  ],
  priority: -1,
  lang: "en",
  activationMessage: "",
  isActiveNow: true,
  isActiveNowInit: false,
  promo: null,
  generatedWithAI: false
}

export const IInitCourseDefault: IInitCourse = {
  ...ICourseDefault,
  initDescription: "",
  initTags: [],
  initLessons: [],
  initActivationMessage: "",
}

export const ICoursePublishDefault: ICoursePublish = {
  id: "",
  title: "",
  skipDays: [],
  status: "draft",
  price: 0,
  forwardOpenAnswers: false,
  forwardsChatLink: undefined,
  forwardsChatId: undefined,
  logo: "",
  category: "",
  lang: "",
  isActiveNow: true,
  isActiveNowInit: false
}
