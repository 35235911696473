import { createTheme } from "@mui/material";

export const darkTheme = createTheme({
  palette: {
    background: {
      default: "#333333",
    },
    text: {
      primary: "var(--white)",
    },
    primary: {
      main: "#6A80FF",
    },
  },
});
export const lightTheme = createTheme({
  palette: {
    background: {
      default: "#e0e0e0",
    },
    text: {
      primary: "#000",
    },
    primary: {
      main: "#6A80FF",
    },
  },
});
export const partyTheme = {
  backgroundColor: "#eeff00",
  textColor: "#ff88cc",
  primary: "#aa00ee",
};

export const COLORS = {
  primary: "#414562",
  backgroundOnHover: "rgba(0,0,0,0.04);",
};
