import { H2 } from "~/components/common/Headings"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Table from "./Table"
import styled from "styled-components"

const TableContainer = styled.div`
  background-color: var(--white);
  padding: 20px 33px;
  border-radius: 8px;
  box-shadow: 0px 5px 10px #f1f2fa;
  margin-top: 16px;
`

interface LastRegistrationsProps {
  data: any[]
}

const LastRegistrations = ({ data }: LastRegistrationsProps) => {
  const { t } = useTranslation() as any
  const [courses, setCourses] = useState<any[]>([])

  React.useEffect(() => {
    setCourses(data)
  }, [data])

  return (
    <TableContainer>
      <H2 mb="28px">{t("last_regs")}</H2>
      <Table rowsPerPage={5} data={courses} />
    </TableContainer>
  )
}

export default LastRegistrations
