import { createAsyncThunk } from "@reduxjs/toolkit"
import AUTH_API, { IOTPRequestRequest } from "~/api/auth"

export const setToken = (token: string) => {
  localStorage.setItem("@token", token)
  localStorage.setItem("@lastLoginTime", JSON.stringify(new Date(Date.now()).getTime()))
}

export const getToken = () => {
  let lastLoginStorage = localStorage.getItem("@lastLoginTime") || "0"
  const lastLoginTime = JSON.parse(lastLoginStorage)
  const now = new Date(Date.now()).getTime()
  const timeAllowed = 1000 * 60 * 30
  const timeSinceLastLogin = now - lastLoginTime
  if (timeSinceLastLogin < timeAllowed) {
    return localStorage.getItem("token")
  }
}

export const deleteToken = () => {
  localStorage.removeItem("@token")
  localStorage.removeItem("@lastLoginTime")
  localStorage.removeItem("@user")
  localStorage.removeItem("@domain")
  localStorage.removeItem("@classroom")
  localStorage.removeItem("@email")
}

export const activate = createAsyncThunk("auth/activate", async ({ email, otc }: IOTPRequestRequest, thunkAPI) => {
  try {
    const response = await AUTH_API.otp({
      otc,
      email,
      type: "verifyEmail",
    })
    if (response.data.token) {
      setToken(response.data.token)
    }
    return response.data
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err?.response?.data?.message || err?.message || "OTP error")
  }
})

export default activate
