import { configureStore } from "@reduxjs/toolkit"
import auth from "./slices/auth"
import course from "./slices/course"
import premium from "./slices/premium"
import user from "./slices/user"
import lessons from "./slices/lessons"
import confirm from "./slices/confirm"
import cats from "./slices/cats"
import sse from "./slices/sse"
import magic from "./slices/magic"

export const store = configureStore({
  reducer: {
    auth,
    course,
    premium,
    user,
    lessons,
    confirm,
    cats,
    sse,
    magic
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
