import axios, { AxiosRequestConfig } from "axios"
import showError from "~/utils/toast/showError"
import i18n from "../localization"

type Methods = "cards.create" | "cards.get_verify_code" | "cards.verify"

const paymeSubApi = async (
  method: Methods,
  params: any,
  config?: AxiosRequestConfig<any>,
) => {
  const res = await axios.post(
    "https://checkout.paycom.uz/api",
    {
      method,
      params,
    },
    {
      ...config,
      headers: { "X-Auth": "63202aa5f16104bde41dd1a0" },
    },
  )

  if (res.data.error) {
    showError(i18n.t(`error_codes.${res.data.error.code}`))

    return false
  }

  return res.data.result
}

const cardCreate = async (
  body: { cardNumber: string; cardExpire: string },
  config?: AxiosRequestConfig<any>,
) => {
  const res = await paymeSubApi(
    "cards.create",
    {
      card: {
        number: body.cardNumber,
        expire: body.cardExpire,
      },
      save: true,
    },
    config,
  )
  return res
}

const cardGetVerifyCode = async (
  token: string,
  config?: AxiosRequestConfig<any>,
) => {
  const res = await paymeSubApi(
    "cards.get_verify_code",
    {
      token,
    },
    config,
  )
  return res
}

const cardVerify = async (
  body: { token: string; code: string },
  config?: AxiosRequestConfig<any>,
) => {
  const res = await paymeSubApi("cards.verify", body, config)
  return res
}

export const PaymeAPI = {
  cardCreate,
  cardGetVerifyCode,
  cardVerify,
}
