import { Dispatch, MouseEvent, SetStateAction, useState } from "react"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MUIButton from "@mui/material/Button"
import useMediaQuery from "@mui/material/useMediaQuery"
import Avatar from "@mui/material/Avatar"
import { Theme } from "@mui/material/styles/createTheme"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import classNames from "classnames"

import {
  BurgerCloseIcon,
  BurgerIcon,
  ChevronDownIcon,
  LogoIcon,
  ProIcon,
} from "~/assets/svg"
import { P3 } from "~/components/common/Paragraph"
import { SETTINGS, SUBSCRIPTIONS } from "~/constants/routes"
import { RUS, UZB } from "~/assets/svg/flags"
import { logout } from "~/context/slices/auth"
import { setInitialPremium } from "~/context/slices/premium"
import useAppSelector from "~/hooks/useAppSelector"
import useAppDispatch from "~/hooks/useAppDispatch"
import InlineBox from "../InlineBox"
import Button from "~/components/common/Button"
import Modal from "../Modal"
import Row from "~/components/common/Row"
import getFileURL from "~/utils/file/getFileURL"

export interface INavbarProps {
  handleLoginOpen: () => void
}

const Navbar = ({
  setIsDrawerOpen,
  isDrawerOpen,
}: {
  isDrawerOpen: boolean
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down(767))
  const currentPlan = useAppSelector((state) => state.premium.currentPlan)
  const userName = useAppSelector((state) => state.user.name)
  const userId = useAppSelector((state) => state.user.id)
  const userAvatar = useAppSelector((state) => state.user.avatar)

  const { i18n } = useTranslation() as any
  const navigate = useNavigate()
  const [_, setLang] = useState(i18n.language)
  const { t } = useTranslation() as any
  const auth = useAppSelector((state) => state.auth) as any
  const [modalLang, setModalLang] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const dispatch = useAppDispatch()

  const handleChangeLanguage = (newLang: "ru" | "en" | "uz") => {
    setLang(newLang)
    i18n.changeLanguage(newLang)
  }

  const handleOpenModal = () => setModalLang(true)
  const handleCloseModal = () => setModalLang(false)

  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    dispatch(logout(true))
    dispatch(setInitialPremium())
  }

  return (
    <>
      <InlineBox className={"ls-navbarClone"} />
      <Row className={"ls-navbar"} justify={"space-between"}>
        <Link to="/" className={"logo"}>
          <LogoIcon />
          {/* {!underMd ? <LogoIcon /> : underSm ? <LogoSMIcon /> : <LogoMDIcon />} */}
        </Link>

        {!underMd ? (
          <Row>
            {auth.isLoggedIn ? (
              <MUIButton onClick={handleClick}>
                <Row align="center">
                  <P3 mr="10px" color="#212339" textTransform="capitalize">
                    {t("hello")}, <strong>{userName ? userName : t("guest")}</strong>
                  </P3>
                  <InlineBox position="relative">
                    <Avatar
                      src={getFileURL(userAvatar, `authors/${userId}`) || undefined}
                      sx={{ width: "34px", height: "34px" }}
                    />

                    {currentPlan === "pro" ? (
                      <InlineBox position="absolute" t="-10px" r="-10px">
                        <ProIcon />
                      </InlineBox>
                    ) : null}
                  </InlineBox>

                  <InlineBox ml="10px" />
                  <ChevronDownIcon />
                </Row>
              </MUIButton>
            ) : (
              <Button>
                <P3 color="var(--white)">{t("authorize")}</P3>
              </Button>
            )}
          </Row>
        ) : (
          <>
            <IconButton className={"profileBtn"} onClick={handleClick}>
              <Avatar
                src={getFileURL(`authors/${userId}`, userAvatar) || undefined}
                sx={{ width: "34px", height: "34px" }}
              />
              {currentPlan === "pro" ? (
                <div className="pro-icon">
                  <ProIcon />
                </div>
              ) : null}
            </IconButton>
            <IconButton
              className={"menu-toggler"}
              onClick={() => setIsDrawerOpen((prev) => !prev)}>
              {isDrawerOpen ? <BurgerCloseIcon /> : <BurgerIcon />}
            </IconButton>
          </>
        )}

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
          }}
          disableScrollLock
          className={classNames("dropdownMenu")}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
          {!underMd ? (
            <div>
              <MenuItem onClick={handleOpenModal}>
                <P3>{t("change_lang")}</P3>
              </MenuItem>
              <MenuItem onClick={() => navigate(SETTINGS)}>
                <P3>{t("settings")}</P3>
              </MenuItem>
              <MenuItem onClick={() => navigate(SUBSCRIPTIONS)}>
                <P3>{t("subscriptions")}</P3>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <P3 color={"var(--red)"}>{t("logout")}</P3>
              </MenuItem>
            </div>
          ) : (
            <div>
              <Row mb="10px" px="5px" align="center">
                <P3>
                  <strong>{userName ? userName : t("guest")}</strong>
                </P3>
              </Row>
              <MenuItem onClick={() => navigate(SETTINGS)}>
                <P3>{t("settings")}</P3>
              </MenuItem>
              <MenuItem onClick={handleOpenModal}>
                <P3>{t("change_lang")}</P3>
              </MenuItem>
              <MenuItem onClick={() => navigate(SUBSCRIPTIONS)}>
                <P3>{t("subscriptions")}</P3>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <P3 color={"var(--red)"}>{t("logout")}</P3>
              </MenuItem>
            </div>
          )}
        </Menu>
      </Row>

      <Modal
        className="max-width-limit"
        handleClose={handleCloseModal}
        open={modalLang}>
        <InlineBox display="flex" alignItems={"center"} justifyContent={"center"}>
          <IconButton
            onClick={() => {
              handleChangeLanguage("uz")
              handleCloseModal()
            }}
            sx={{ width: "70px", height: "70px", marginRight: "20px" }}>
            <UZB width="70" height="70" />
          </IconButton>
          {/* <IconButton
            onClick={() => {
              handleChangeLanguage("en")
              handleCloseModal()
            }}
            sx={{ width: "70px", height: "70px", marginRight: "20px" }}>
            <ENG width="70" height="70" />
          </IconButton> */}
          <IconButton
            onClick={() => {
              handleChangeLanguage("ru")
              handleCloseModal()
            }}
            sx={{ width: "70px", height: "70px" }}>
            <RUS width="70" height="70" />
          </IconButton>
        </InlineBox>
      </Modal>
    </>
  )
}

export default Navbar
