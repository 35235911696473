import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type ICourseTopic = {
  course: string[]
}

type ICourseContent = {
  course: ICourseTopic[]
}


export const magicInitialState: ICourseTopic = {
  course: []
}

export const magicSlice = createSlice({
  name: "magic",
  initialState: magicInitialState,
  reducers: {
    setCourseTopics: (state, action: PayloadAction<string[]>) => {
      state.course = action.payload
    },

  },
})

export const magicActions = magicSlice.actions

export default magicSlice.reducer
