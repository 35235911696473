import { H2, H4, H5 } from "~/components/common/Headings"
import { useTranslation } from "react-i18next"
import Modal from "~/components/common/Modal"
import useAppSelector from "~/hooks/useAppSelector"
import {
  CheckboxCheckedIcon,
  CrownIcon,
  PaymeIcon,
  RectangleIcon,
} from "~/assets/svg"
import useAppDispatch from "~/hooks/useAppDispatch"
import { setAutoRenewal, setPaymentMethod } from "~/context/slices/premium"
import { PremiumInitialState } from "~/context/initialStates/premium"
import classNames from "classnames"
import Row from "~/components/common/Row"
import { Checkbox, CircularProgress } from "@mui/material"
import { P4 } from "~/components/common/Paragraph"
import { useEffect, useState } from "react"
import InlineBox from "~/components/common/InlineBox"
import { useNavigate } from "react-router-dom"
import { SUBSCRIPTIONS } from "~/constants/routes"

import plans from "~/constants/plans"
import showError from "~/utils/toast/showError"
import usePremium from "~/hooks/usePremium"

const SelectPaymentMethod = ({
  open,
  handleClose,
}: {
  open: boolean
  handleClose: () => void
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { purchaseSubscription } = usePremium()

  const { bill, paymentMethod, autoRenewal, mainWallet, cards } = useAppSelector(
    (state) => state.premium,
  )
  const { t } = useTranslation() as any
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState<boolean | null>(null)
  const [error, setError] = useState("")

  const handleSelectMethod = (
    paymentMethod: PremiumInitialState["paymentMethod"],
  ) => {
    return async () => {
      try {
        dispatch(setPaymentMethod(paymentMethod))

        setLoading(true)

        await purchaseSubscription(() => {
          // ON SUCCESS
          setSuccess(true)
        })

        setLoading(false)
      } catch (err: any) {
        setError(
          err?.response?.data?.message.split(": ")[1] ||
            err?.response?.data?.message,
        )

        showError(err)
        setLoading(false)
        setSuccess(false)
      }
    }
  }

  useEffect(() => {
    if (!open) return
    if (cards.length && (mainWallet || mainWallet === 0)) return
    if (paymentMethod === null) return

    handleSelectMethod(paymentMethod)()
  }, [open])

  const handleAutoRenewal = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    dispatch(setAutoRenewal(checked))
  }

  if (success !== null) {
    return (
      <Modal
        className="max-width-limit"
        handleClose={() => {
          handleClose()
          setSuccess(null)
          setError("")
        }}
        open={open}>
        <InlineBox
          mb="33px"
          display="flex"
          alignItems={"center"}
          justifyContent="center">
          {success ? (
            <svg
              width="104"
              height="104"
              viewBox="0 0 104 104"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_5977_15385)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M52 0C23.3188 0 0 23.3188 0 52C0 80.6812 23.3188 104 52 104C80.6812 104 104 80.6812 104 52C104 23.3188 80.6812 0 52 0Z"
                  fill="#4BAE4F"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M77.1465 34.4705C78.4059 35.7299 78.4059 37.8018 77.1465 39.0611L46.6777 69.5299C46.048 70.1596 45.2152 70.4846 44.3824 70.4846C43.5496 70.4846 42.7168 70.1596 42.0871 69.5299L26.8527 54.2955C25.5934 53.0361 25.5934 50.9643 26.8527 49.7049C28.1121 48.4455 30.184 48.4455 31.4434 49.7049L44.3824 62.6439L72.5559 34.4705C73.8152 33.1908 75.8871 33.1908 77.1465 34.4705Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_5977_15385">
                  <rect width="104" height="104" fill="white" />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <svg
              width="104"
              height="104"
              viewBox="0 0 104 104"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52 0C23.3188 0 0 23.3188 0 52C0 80.6812 23.3188 104 52 104C80.6812 104 104 80.6812 104 52C104 23.3188 80.6812 0 52 0Z"
                fill="#FF3A29"
              />
              <rect
                x="30"
                y="67.7344"
                width="53.3646"
                height="8.1207"
                rx="4.06035"
                transform="rotate(-45 30 67.7344)"
                fill="white"
              />
              <rect
                x="35.7422"
                y="30"
                width="53.3646"
                height="8.1207"
                rx="4.06035"
                transform="rotate(45 35.7422 30)"
                fill="white"
              />
            </svg>
          )}
        </InlineBox>

        <H2 textAlign="center" mb="10px">
          {success
            ? t("success_messages.pay_by_card")
            : t("error_messages.pay_by_card")}
        </H2>
        {error ? (
          <P4 textAlign="center">
            {t("error")}: {error}
          </P4>
        ) : null}

        {success ? (
          <InlineBox
            mt="45px"
            display="flex"
            alignItems="center"
            justifyContent={"center"}>
            <button
              onClick={() => {
                navigate(SUBSCRIPTIONS)
                handleClose()
                setSuccess(null)
                setError("")
              }}
              className="premium__modalBtn">
              {t("view_my_subs")}
            </button>
          </InlineBox>
        ) : (
          <InlineBox
            mt="45px"
            display="flex"
            alignItems="center"
            justifyContent={"center"}>
            <button
              onClick={() => {
                navigate(SUBSCRIPTIONS)
                handleClose()
                setSuccess(null)
                setError("")
              }}
              className="premium__modalBtn">
              {t("change_payment_method")}
            </button>
          </InlineBox>
        )}
      </Modal>
    )
  }

  if (loading) {
    return (
      <Modal
        className="max-width-limit"
        hideCloseBtn
        handleClose={handleClose}
        open={open}>
        <H2 textAlign={"center"} mb="15px">
          {t("payment_processing")}
        </H2>

        <InlineBox
          mt="40px"
          mb="40px"
          display="flex"
          alignItems="center"
          justifyContent={"center"}>
          <CircularProgress size={100} />
        </InlineBox>
      </Modal>
    )
  }

  return (
    <Modal className="max-width-limit" handleClose={handleClose} open={open}>
      <CrownIcon />
      <H2 mb="18px">
        {bill === "annualy"
          ? t("annual_subscription_plan", { plan: "PRO" })
          : t("monthly_subscription_plan", { plan: "PRO" })}
      </H2>
      <H5 mb="15px">{t("select_payment_method")}</H5>

      <button
        onClick={handleSelectMethod("payme")}
        className={classNames("premium__selectPayment-btn mb-10", {
          disabled: paymentMethod !== null && paymentMethod !== "payme",
          active: paymentMethod === "payme",
        })}>
        <PaymeIcon />

        <H4>
          {bill === "annualy" ? plans.pro.price.annualy : plans.pro.price.monthly}{" "}
          {t("uzs")}
        </H4>
      </button>
      {/* @ts-ignore */}
      {/* <Tooltip placement="bottom" title={t("soon")}>
        <div
          // onClick={handleSelectMethod("click")}
          // disabled
          className={classNames("premium__selectPayment-btn mb-10", {
            disabled: paymentMethod !== null && paymentMethod !== "click",
            active: paymentMethod === "click",
          })}>
          <ClickIcon />

          <H4>
            {bill === "annualy" ? plans.pro.price.annualy : plans.pro.price.monthly}{" "}
            {t("uzs")}
          </H4>
        </div>
      </Tooltip> */}

      <Row align={"center"}>
        <Checkbox
          value={autoRenewal}
          icon={<RectangleIcon />}
          checkedIcon={<CheckboxCheckedIcon />}
          onChange={handleAutoRenewal}
          name="auto_renewal"
          id="auto_renewal"
        />
        <label htmlFor="auto_renewal">
          <P4>{t("auto_renewal")}</P4>
        </label>
      </Row>
    </Modal>
  )
}

export default SelectPaymentMethod
