import React, { useState } from "react"
import Chart from "react-apexcharts"
import { useTranslation } from "react-i18next"
import { en, ru, uz } from "./localization"

interface ChartProps {
  data: [number | undefined, number | undefined]
}

const PieChart = ({ data }: ChartProps) => {
  const { i18n } = useTranslation() as any

  const [options, setOptions] = useState<ApexCharts.ApexOptions>({
    chart: {
      id: "pie-chart",
      locales: [en, ru, uz],
      width: 350,
      height: 350,
      defaultLocale: i18n.language.split(/-|_/)[0] || "ru",
      animations: {
        dynamicAnimation: {
          enabled: true,
        },
      },
    },
    dataLabels: {
      formatter: (val: number) => `${val.toString().slice(0, 4)}%`,
      style: {
        fontFamily: "var(--font)",
        fontWeight: "700",
        fontSize: "14px",
      },
      dropShadow: {
        enabled: false,
      },
    },
    colors: ["#008FFB", "#00E396"],

    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    legend: {
      position: "bottom",
      fontFamily: "var(--font)",
    },
    labels: ["Telegram", "WhatsApp"],
  })
  const [series, setSeries] = useState<[number | undefined, number | undefined]>()

  React.useEffect(() => {
    if (data[0] !== undefined || data[1] !== undefined) {
      setSeries(data)
    }
  }, [data])

  if (
    (series?.length && series[0] === undefined) ||
    (series?.length && series[1] === undefined)
  ) {
    return null
  }

  return (
    <div id="pieChart" style={{ height: "100%", overflow: "hidden" }}>
      {/* @ts-ignore */}
      <Chart options={options} series={series || []} type="pie" height={"100%"} />
    </div>
  )
}

export default PieChart
