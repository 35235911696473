export const DASHBOARD = "/"
export const SIGN_IN = "/sign-in"
export const SIGN_UP = "/sign-up"
export const FORGOT_PASSWD = "/forgot-password"
export const FORGOT_PASSWD_OTP = "/otp-r"
export const RESET_PASSWD = "/reset-password"
export const OTP = "/otp"
export const COURSES = "/courses"
export const COURSE_CREATE = "/course-create"
export const PAYMENT_METHODS = "/payment-methods"
export const SUPPORT = "/support"
export const COURSE_EDIT = "/course-edit"
export const COURSE_VIEW = "/course-view"
export const MY_USERS = "/my-users"
export const SUBSCRIPTIONS = "/subscriptions"
export const CLASSROOM_CREATE = "/classroom-create"
export const STOREFRONT = "/storefront"
export const SETTINGS = "/settings"
export const ANALYTICS = "/analytics"
export const PRO = "/pro"
export const FAQ = "/faq"
