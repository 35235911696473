import axios from "~/utils/axios";
import { AxiosRequestConfig } from "axios";

export type ISignUpRequestBody = {
  email: string;
  password: string;
};

export type ICreateStoreRequestBody = {
  activateToken: string;
  name: string;
  domain: string;
  avatar?: string;
};

export type IOTPRequestRequest = {
  email: string;
  otc: string;
  type: "resetPasswd" | "verifyEmail";
};

export type ISignInRequestBody = {
  email: string;
  password: string;
};

export type IForgotPasswdRequestBody = {
  email: string;
};

export type IResetPasswdRequestBody = {
  newPasswd: string;
  resetToken: string;
};

export type IChangePasswdRequestBody = {
  newPasswd: string;
  currentPasswd: string;
};

const signUp = async (body: ISignUpRequestBody, config?: AxiosRequestConfig<any>) => {
  const res = await axios.post<{ verifyToken: string }>("/dash/auth/signup", body, config);
  const date = new Date();
  date.setMinutes(date.getMinutes() + 5);
  localStorage.setItem("@expiresIn", JSON.stringify(date.getTime()));
  return res;
};

const signIn = async (body: ISignInRequestBody, config?: AxiosRequestConfig<any>) => {
  const res = await axios.post("/dash/auth/signin", body, config);
  const date = new Date();
  date.setMinutes(date.getMinutes() + 5);
  localStorage.setItem("@expiresIn", JSON.stringify(date.getTime()));
  return res;
};

const login = async (email: string, config?: AxiosRequestConfig<any>) => {
  const res = await axios.post(
    "/dash/login/author",
    {
      email,
    },
    config
  );
  const date = new Date();
  date.setMinutes(date.getMinutes() + 5);
  localStorage.setItem("@expiresIn", JSON.stringify(date.getTime()));
  return res;
};

const otp = async (body: IOTPRequestRequest, config?: AxiosRequestConfig<any>) => {
  const res = await axios.post("/dash/auth/otp", body, config);
  return res;
};

const forgotPassword = async (body: IForgotPasswdRequestBody, config?: AxiosRequestConfig<any>) => {
  const res = await axios.post("/dash/auth/forgotPasswd", body, config);
  return res;
};

const resetPassword = async (body: IResetPasswdRequestBody, config?: AxiosRequestConfig<any>) => {
  const res = await axios.post("/dash/auth/resetPasswd", body, config);
  return res;
};

const changePassword = async (body: IChangePasswdRequestBody, config?: AxiosRequestConfig<any>) => {
  const res = await axios.post("/dash/auth/changePasswd", body, config);
  return res;
};

const authMe = async (token: string, config?: AxiosRequestConfig<any>) => {
  const res = await axios.get("/dash", {
    headers: { Authorization: `Bearer ${token}` },
    ...config,
  });
  return res;
};

const createStore = async (body: ICreateStoreRequestBody, config?: AxiosRequestConfig<any>) => {
  const res = await axios.post("/dash/auth/createStore", body, {
    ...config,
  });
  return res;
};

const googleOAuth = async (code: string, redirectUri: string, config?: AxiosRequestConfig<any>) => {
  const res = await axios.post(
    "/dash/auth/google",
    {
      code,
      redirectUri,
    },
    config
  );
  return res;
};

const AUTH_API = {
  signUp,
  signIn,
  otp,
  login,
  authMe,
  createStore,
  resetPassword,
  forgotPassword,
  googleOAuth,
  changePassword,
};

export default AUTH_API;
