import { useTranslation } from "react-i18next"
import { ButtonProps } from "@mui/material"

import { GoogleButton } from "~/components/common/Button"
import { GoogleIcon } from "~/assets/svg"

export interface IGoogleOAuthProps extends ButtonProps {
  title?: string
}

const GoogleOAuth = ({ title = "", ...props }: IGoogleOAuthProps) => {
  const redirectUri = window.location.href

  const { t } = useTranslation() as any
  return (
    <a
      href={`https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${redirectUri}&client_id=778653890805-9qptrpoculaoh4apkavfedop2gshk0sd.apps.googleusercontent.com&access_type=offline&response_type=code&prompt=consent&scope=https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile`}>
      <GoogleButton prefixIcon {...props}>
        <GoogleIcon />
        {title || `${t("continue_with", { val: "Google" })} `}
      </GoogleButton>
    </a>
  )
}

export default GoogleOAuth
