export const UZB = ({ fill = "#04AAC8",
                      stroke = "#F5F5F5",
                      strokeWidth = "0.5",
                      strokeColor = "white",
                      svgWidth = '151',
                      svgHeight = '107',
                      ...props }) => {
  return (
    <svg width={svgWidth} height={svgHeight} viewBox="0 0 151 107" fill="none" {...props}>
      <path
        d="M140.214 1.33752H10.7857C5.57352 1.33752 1.34821 5.52926 1.34821 10.7V96.3C1.34821 101.471 5.57352 105.663 10.7857 105.663H140.214C145.426 105.663 149.652 101.471 149.652 96.3V10.7C149.652 5.52926 145.426 1.33752 140.214 1.33752Z"
        fill="white"
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
      <mask
        id="mask0_1_1965"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="149"
        height="105"
      >
        <path
          d="M140.214 1.33752H10.7857C5.57352 1.33752 1.34821 5.52926 1.34821 10.7V96.3C1.34821 101.471 5.57352 105.663 10.7857 105.663H140.214C145.426 105.663 149.652 101.471 149.652 96.3V10.7C149.652 5.52926 145.426 1.33752 140.214 1.33752Z"
          fill="white"
          stroke="white"
          strokeWidth={strokeWidth}
        />
      </mask>
      <g mask="url(#mask0_1_1965)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 35.6667H151V0H0V35.6667Z" fill={fill} />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 106.998H151V71.3315H0V106.998Z" fill={"#23C840"} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.1665 28.5316C27.3844 28.5316 29.446 27.8674 31.1602 26.7286C30.9616 26.7416 30.7612 26.7482 30.5593 26.7482C25.5953 26.7482 21.5712 22.7561 21.5712 17.8316C21.5712 12.907 25.5953 8.91491 30.5593 8.91491C30.7612 8.91491 30.9616 8.92154 31.1602 8.93454C29.446 7.79574 27.3844 7.13159 25.1665 7.13159C19.2097 7.13159 14.3807 11.9221 14.3807 17.8316C14.3807 23.741 19.2097 28.5316 25.1665 28.5316ZM43.1426 24.9649C43.1426 26.9347 41.533 28.5316 39.5474 28.5316C37.5618 28.5316 35.9522 26.9347 35.9522 24.9649C35.9522 22.9951 37.5618 21.3982 39.5474 21.3982C41.533 21.3982 43.1426 22.9951 43.1426 24.9649ZM53.9283 14.2649C55.9137 14.2649 57.5234 12.668 57.5234 10.6982C57.5234 8.72841 55.9137 7.13159 53.9283 7.13159C51.9427 7.13159 50.3331 8.72841 50.3331 10.6982C50.3331 12.668 51.9427 14.2649 53.9283 14.2649ZM71.9046 10.6982C71.9046 12.668 70.2948 14.2649 68.3091 14.2649C66.3235 14.2649 64.7143 12.668 64.7143 10.6982C64.7143 8.72841 66.3235 7.13159 68.3091 7.13159C70.2948 7.13159 71.9046 8.72841 71.9046 10.6982ZM68.3091 28.5316C70.2948 28.5316 71.9046 26.9347 71.9046 24.9649C71.9046 22.9951 70.2948 21.3982 68.3091 21.3982C66.3235 21.3982 64.7143 22.9951 64.7143 24.9649C64.7143 26.9347 66.3235 28.5316 68.3091 28.5316ZM57.5234 24.9649C57.5234 26.9347 55.9137 28.5316 53.9283 28.5316C51.9427 28.5316 50.3331 26.9347 50.3331 24.9649C50.3331 22.9951 51.9427 21.3982 53.9283 21.3982C55.9137 21.3982 57.5234 22.9951 57.5234 24.9649Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export const RUS = ({
                      fill = "#0C47B7",
                      stroke = "#F5F5F5",
                      strokeWidth = "0.5",
                      strokeColor = "white",
                      svgWidth = '151',
                      svgHeight = '107',
                      ...props }) => {
  return (
    <svg width={svgWidth} height={svgHeight} viewBox="0 0 151 110" fill="none" {...props}>
      <path
        d="M140.214 1.375H10.7857C5.57352 1.375 1.34821 5.68426 1.34821 11V99C1.34821 104.316 5.57352 108.625 10.7857 108.625H140.214C145.426 108.625 149.652 104.316 149.652 99V11C149.652 5.68426 145.426 1.375 140.214 1.375Z"
        fill="white"
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
      <mask
        id="mask0_1_1602"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="149"
        height="108"
      >
        <path
          d="M140.214 1.375H10.7857C5.57352 1.375 1.34821 5.68426 1.34821 11V99C1.34821 104.316 5.57352 108.625 10.7857 108.625H140.214C145.426 108.625 149.652 104.316 149.652 99V11C149.652 5.68426 145.426 1.375 140.214 1.375Z"
          fill="white"
          stroke="white"
          strokeWidth={strokeWidth}
        />
      </mask>
      <g mask="url(#mask0_1_1602)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 73.3354H151V36.6685H0V73.3354Z" fill={fill} />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 109.998H151V73.3315H0V109.998Z" fill={"#E53B35"} />
      </g>
    </svg>
  );
};

export const ENG = ({
  fill = "#0A17A7",
  stroke = "#DB1F35",
  strokeWidth = "0.666667",
  strokeColor = "white",
  ...props
}) => {
  return (
    <svg width="155" height="111" viewBox="0 0 155 111" fill="none" {...props}>
      <path
        d="M143.929 0H11.0714C4.95685 0 0 4.96964 0 11.1V99.9C0 106.03 4.95685 111 11.0714 111H143.929C150.043 111 155 106.03 155 99.9V11.1C155 4.96964 150.043 0 143.929 0Z"
        fill="white"
      />
      <mask
        id="mask0_1_688"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="155"
        height="111"
      >
        <path
          d="M143.929 0H11.0714C4.95685 0 0 4.96964 0 11.1V99.9C0 106.03 4.95685 111 11.0714 111H143.929C150.043 111 155 106.03 155 99.9V11.1C155 4.96964 150.043 0 143.929 0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1_688)">
        <path d="M155 0H0V111H155V0Z" fill={"#0A17A7"} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.09953 -10.6339L59.0473 34.098V-7.3976H95.9522V34.098L162.099 -10.6339L170.354 1.63587L118.056 37.0024H155V74.0026H118.056L170.354 109.369L162.099 121.639L95.9522 76.9069V118.403H59.0473V76.9069L-7.09953 121.639L-15.3543 109.369L36.9438 74.0026H-0.000153542V37.0024H36.9438L-15.3543 1.63587L-7.09953 -10.6339Z"
          fill="white"
        />
        <path d="M103.341 35.1437L173.452 -11.1" stroke={"#E6273E"} strokeWidth={strokeWidth} strokeLinecap="round" />
        <path d="M110.785 76.0228L173.637 118.496" stroke={"#E6273E"} strokeWidth={strokeWidth} strokeLinecap="round" />
        <path
          d="M44.3161 35.0236L-21.2434 -9.27349"
          stroke={"#E6273E"}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
        />
        <path
          d="M51.4268 75.5078L-21.2434 123.823"
          stroke={"#E6273E"}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 66.6H66.4286V111H88.5714V66.6H155V44.4H88.5714V0H66.4286V44.4H0V66.6Z"
          fill={"#E6273E"}
        />
      </g>
    </svg>
  );
};
