import React from "react"
import {
  AnalyticsIcon,
  CourseBuilderIcon,
  CrownIcon,
  DashboardIcon,
  StorefrontIcon,
  SubscriptionIcon,
  SupportIcon,
  WalletIcon,
} from "./assets/svg"
import {
  ANALYTICS,
  COURSES,
  COURSE_CREATE,
  COURSE_EDIT,
  COURSE_VIEW,
  DASHBOARD,
  PAYMENT_METHODS,
  PRO,
  SETTINGS,
  STOREFRONT,
  MY_USERS,
  SUBSCRIPTIONS,
  SUPPORT,
} from "./constants/routes"
import Pro from "./pages/Pro"

const CourseEdit = React.lazy(() => import("~/pages/CourseEdit"))
const Analytics = React.lazy(() => import("~/pages/Analytics"))
const Courses = React.lazy(() => import("~/pages/Courses"))
const MyUsers = React.lazy(() => import("~/pages/MyUsers"))
const Subscriptions = React.lazy(() => import("~/pages/Subscriptions"))
const Storefront = React.lazy(() => import("~/pages/Storefront"))
const PaymentMethods = React.lazy(() => import("~/pages/PaymentMethods"))
const Support = React.lazy(() => import("~/pages/Support"))
const Settings = React.lazy(() => import("~/pages/Settings"))
const CourseView = React.lazy(() => import("~/pages/CourseView"))

export enum ROLES {
  super_admin = "super-admin",
  admin = "admin",
  author = "author",
  editor = "editor",
  corrector = "corrector",
  moderator = "moderator",
  translator = "translator",
  none = "none",
}

export const ROUTES = [
  {
    path: COURSES,
    name: "my_courses",
    component: <Courses />,
    permission: [
      ROLES.author,
      ROLES.admin,
      ROLES.super_admin,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
      ROLES.editor,
    ],
  },
  {
    path: MY_USERS,
    name: "my_users",
    component: <MyUsers />,
    permission: [
      ROLES.author,
      ROLES.admin,
      ROLES.super_admin,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
      ROLES.editor,
    ],
  },
  {
    path: SUBSCRIPTIONS,
    component: <Subscriptions />,
    permission: [
      ROLES.author,
      ROLES.admin,
      ROLES.super_admin,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
      ROLES.editor,
    ],
  },
  {
    path: ANALYTICS,
    name: "my_analytics",
    component: <Analytics />,
    permission: [
      ROLES.author,
      ROLES.admin,
      ROLES.super_admin,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
      ROLES.editor,
    ],
  },
  {
    path: STOREFRONT,
    name: "classroom_homepage",
    component: <Storefront />,
    permission: [
      ROLES.author,
      ROLES.admin,
      ROLES.super_admin,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
      ROLES.editor,
    ],
  },
  {
    path: PAYMENT_METHODS,
    name: "payment_methods",
    component: <PaymentMethods />,
    permission: [
      ROLES.author,
      ROLES.admin,
      ROLES.super_admin,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
      ROLES.editor,
    ],
  },
  {
    path: PAYMENT_METHODS,
    name: "payment_methods",
    component: <PaymentMethods />,
    permission: [
      ROLES.author,
      ROLES.admin,
      ROLES.super_admin,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
      ROLES.editor,
    ],
  },
  {
    path: SUPPORT,
    name: "support",
    component: <Support />,
    permission: [
      ROLES.author,
      ROLES.admin,
      ROLES.super_admin,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
      ROLES.editor,
    ],
  },
  {
    path: SETTINGS,
    name: "settings",
    component: <Settings />,
    permission: [
      ROLES.author,
      ROLES.admin,
      ROLES.super_admin,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
      ROLES.editor,
    ],
  },
  {
    path: COURSE_CREATE,
    name: "course-create",
    component: <CourseEdit />,
    permission: [
      ROLES.author,
      ROLES.admin,
      ROLES.super_admin,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
      ROLES.editor,
    ],
  },
  {
    path: `${COURSE_EDIT}/:id`,
    name: "course-edit",
    component: <CourseEdit />,
    permission: [
      ROLES.author,
      ROLES.admin,
      ROLES.super_admin,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
      ROLES.editor,
    ],
  },
  {
    path: `${COURSE_VIEW}/:id`,
    name: "course-view",
    component: <CourseView />,
    permission: [
      ROLES.author,
      ROLES.admin,
      ROLES.super_admin,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
      ROLES.editor,
    ],
  },
  {
    path: PRO,
    name: "pro",
    component: <Pro />,
    permission: [
      ROLES.author,
      ROLES.admin,
      ROLES.super_admin,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
      ROLES.editor,
    ],
  }
]

export const DRAWER_ROUTES = [
  {
    path: DASHBOARD,
    name: "dashboard",
    icon: <DashboardIcon width="18" height="18" />,
    permission: [
      ROLES.author,
      ROLES.admin,
      ROLES.super_admin,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
      ROLES.editor,
    ],
  },
  {
    path: COURSES,
    name: "my_courses",
    icon: <CourseBuilderIcon width="18" height="18" />,
    permission: [
      ROLES.author,
      ROLES.admin,
      ROLES.super_admin,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
      ROLES.editor,
    ],
  },
  {
    path: MY_USERS,
    name: "my_users",
    icon: <SubscriptionIcon width="18" height="18" />,
    permission: [
      ROLES.author,
      ROLES.admin,
      ROLES.super_admin,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
      ROLES.editor,
    ],
  },
  {
    path: ANALYTICS,
    name: "my_analytics",
    icon: <AnalyticsIcon width="18" height="18" />,
    permission: [
      ROLES.author,
      ROLES.admin,
      ROLES.super_admin,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
      ROLES.editor,
    ],
  },
  {
    path: STOREFRONT,
    name: "classroom_homepage",
    icon: <StorefrontIcon width="18" height="18" />,
    permission: [
      ROLES.author,
      ROLES.admin,
      ROLES.super_admin,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
      ROLES.editor,
    ],
  },
  {
    path: PAYMENT_METHODS,
    name: "payment_methods",
    icon: <WalletIcon width="18" height="18" />,
    permission: [
      ROLES.author,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
      ROLES.editor,
      ROLES.admin,
      ROLES.super_admin,
    ],
  },
  {
    path: SUPPORT,
    name: "support",
    icon: <SupportIcon width="18" height="18" />,
    permission: [
      ROLES.author,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
      ROLES.editor,
      ROLES.admin,
      ROLES.super_admin,
    ],
  },
  {
    path: PRO,
    name: "pro",
    pro: true,
    icon: <CrownIcon width="18" height="18" />,
    permission: [
      ROLES.admin,
      ROLES.super_admin,
      ROLES.author,
      ROLES.corrector,
      ROLES.moderator,
      ROLES.translator,
    ],
  },
]
