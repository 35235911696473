import { useTranslation } from "react-i18next"
import { P3, P2 } from "~/components/common/Paragraph"
import { H4 } from "~/components/common/Headings"
import Status from "~/components/Status"
import {SHORT_MONTHS} from "~/constants/months";
import {formatTimeWithZero} from "~/utils/time/formatTimeWithZero";

export interface ITableRow {
  name?: string
  dateOfReg: Date | string
  status?: string
  courseName: string
  price: number
  paymentMethod?: string
  invoice?: any
  index?: number
  id?: string
  key?: string
}

const TableRow = ({
  name,
  dateOfReg,
  status,
  courseName,
  paymentMethod,
  price,
}: ITableRow) => {
  const { t } = useTranslation() as any
  const regDate = new Date(dateOfReg)
  const userRegDate = `${regDate.getDate()} ${t(`full_months.${SHORT_MONTHS[regDate.getMonth()]}`)} ${regDate.getFullYear()}, ${formatTimeWithZero(regDate.getHours())}:${formatTimeWithZero(regDate.getMinutes())}`

  return (
    <tr className={"ls-table__row"}>
      <td width="445px" className={"ls-table__cell"} align="left">
        <P3 numberOfLine={2}>{name}</P3>
      </td>
      <td className={"ls-table__cell"} align="left">
        <P3 fontSize="15px">{userRegDate}</P3>
      </td>
      <td className={"ls-table__cell"} align="left">
        <P2 textTransform="capitalize">
          <span className={`subscriptions__status-${status}`}
                style={{padding: '4px', borderRadius:'4px'}}>{status}</span>
        </P2>
      </td>
      <td className={"ls-table__cell"} align="left">
        <H4 numberOfLine={1}>{courseName}</H4>
      </td>
      <td className={"ls-table__cell"} align="left">
        <P3>{price} {t('uzs')}</P3>
      </td>
      <td className={"ls-table__cell"} align="left">
        <P3>{paymentMethod}</P3>
      </td>
    </tr>
  )
}

export default TableRow
