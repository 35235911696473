import React, { useRef } from "react"
import { createPortal } from "react-dom"
import { useTranslation } from "react-i18next"
import { CloseIcon } from "~/assets/svg"
import useAppSelector from "~/hooks/useAppSelector"
import useConfirm from "~/hooks/useConfirm"

const ConfirmDialog = () => {
  const { t } = useTranslation() as any
  const confirmState = useAppSelector((s) => s.confirm)
  const { onConfirm, onCancel } = useConfirm()
  const overlayRef = useRef<HTMLDivElement>(null)

  const handleCloseDialog = (e: React.MouseEvent) => {
    // @ts-ignore
    if (overlayRef.current && e.target.contains(overlayRef.current)) {
      onCancel()
    }
  }

  const portalElement = document.getElementById("confirm-modal")
  const component = confirmState.show ? (
    <div className="portal-overlay" ref={overlayRef} onClick={handleCloseDialog}>
      <div className="confirm-dialog">
        <button onClick={onCancel} className="btn-icon btn-close">
          <CloseIcon />
        </button>
        {confirmState?.title ? <h4>{confirmState.title}</h4> : null}
        {confirmState?.content ? <p>{confirmState.content}</p> : null}
        <div className="confirm-dialog__footer">
          <div className="btn btn-cancel" onClick={onCancel}>
            {t("cancel")}
          </div>
          <div className="btn btn-confirm" onClick={onConfirm}>
            {t("confirm")}
          </div>
        </div>
      </div>
    </div>
  ) : null

  if (portalElement === null) {
    return null
  }
  return createPortal(component, portalElement)
}
export default ConfirmDialog
