import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AuthInitialState, authInitialStates } from "~/context/initialStates/auth"
import { activate, deleteToken, setToken } from "~/context/reducers/auth/activate"
import login from "~/context/reducers/auth/login"
import signUp from "../reducers/auth/signUp"

const initialState: AuthInitialState = authInitialStates

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticate: (state, action: PayloadAction<any>) => {
      state.isLoggedIn = true
      state.loading = false
      state.error = null
      localStorage.removeItem("@expiresIn")

      const token = localStorage.getItem("@token")
      if (!token) {
        setToken(action.payload.token)
      }
    },
    clearAuth: (state) => {
      state.error = null
    },
    logout: (state, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) {
        deleteToken()
      }
      state.isLoggedIn = false
      state.error = null
    },
  },
  extraReducers: {
    [login.fulfilled.type]: (
      state,
      action: PayloadAction<{
        token: string
        ok?: number
      }>,
    ) => {
      if (action.payload.ok === -1) {
        state.isLoggedIn = false
      } else {
        state.isLoggedIn = true
      }
      state.loading = false
    },
    [login.pending.type]: (state) => {
      state.loading = true
    },
    [login.rejected.type]: (state, action: PayloadAction<any>) => {
      state.loading = false
      state.isLoggedIn = false
      state.error = action.payload
    },
    [signUp.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.loading = false
    },
    [signUp.pending.type]: (state) => {
      state.loading = true
    },
    [signUp.rejected.type]: (state, action: PayloadAction<any>) => {
      state.loading = false
      state.isLoggedIn = false
      state.error = action.payload
    },
    [activate.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.isLoggedIn = false
      state.error = null
    },
    [activate.pending.type]: (state) => {
      state.loading = true
    },
    [activate.rejected.type]: (state, action: PayloadAction<any>) => {
      state.loading = false
      state.isLoggedIn = false
      state.error = action.payload
    },
  },
})

export const { authenticate, clearAuth, logout } = authSlice.actions

export default authSlice.reducer
