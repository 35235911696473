import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  IBody,
  IChoice,
  IContent,
  ILesson,
  IMediaContent,
  OpenEnded,
  YesNo,
} from "~/types/course"

type ILessonsSlice = {
  lessons: ILesson[]
  initLessons: ILesson[]
}

export const lessonsInitialState: ILessonsSlice = {
  lessons: [
    {
      subject: "",
      content: [
        {
          type: "body",
          id: "body_0",
          media: [],
          text: "",
        },
      ],
    },
  ],
  initLessons: [
    {
      subject: "",
      content: [
        {
          type: "body",
          id: "body_0",
          media: [],
          text: "",
        },
      ],
    },
  ],
}

export const lessonsSlice = createSlice({
  name: "lessons",
  initialState: lessonsInitialState,
  reducers: {
    // LESSON
    setLessons: (state, action: PayloadAction<ILesson[]>) => {
      state.lessons = action.payload
    },
    setInitialLessons: (state, action: PayloadAction<ILesson[]>) => {
      state.initLessons = action.payload
    },
    addLesson: (state, action: PayloadAction<ILesson>) => {
      state.initLessons.push(action.payload)
      state.lessons.push(action.payload)
    },
    removeLesson: (state, { payload }: PayloadAction<{ lessonIndex: number }>) => {
      state.lessons = state.lessons.filter((_, i) => i !== payload.lessonIndex)
      state.initLessons = state.initLessons.filter(
        (_, i) => i !== payload.lessonIndex,
      )
    },
    setLessonSubject: (
      state,
      { payload }: PayloadAction<{ lessonIndex: number; value: string }>,
    ) => {
      state.lessons[payload.lessonIndex].subject = payload.value
    },
    setLessonBody: (
      state,
      { payload }: PayloadAction<{ lessonIndex: number; value: string }>,
    ) => {
      const currentLesson = state.lessons[payload.lessonIndex]
      if (currentLesson?.content?.length) {
        const content = currentLesson.content[0] as IBody
        content.text = payload.value
      }
    },
    setContentMedia: (
      state,
      {
        payload,
      }: PayloadAction<{
        lessonIndex: number
        value: IMediaContent[]
        contentId: number
      }>,
    ) => {
      state.lessons[payload.lessonIndex].content[payload.contentId].media = [
        ...(state.lessons[payload.lessonIndex].content[payload.contentId].media ||
          []),
        ...payload.value,
      ]
    },
    setYesResponseMedia: (
      state,
      {
        payload,
      }: PayloadAction<{ lessonIndex: number; value: string[]; contentId: number }>,
    ) => {
      const content = state.lessons[payload.lessonIndex].content[payload.contentId]

      if (content.type === "yes_no") {
        content.yesMedia = [...(content.yesMedia || []), ...payload.value]
      }
    },
    setNoResponseMedia: (
      state,
      {
        payload,
      }: PayloadAction<{ lessonIndex: number; value: string[]; contentId: number }>,
    ) => {
      const content = state.lessons[payload.lessonIndex].content[payload.contentId]

      if (content.type === "yes_no") {
        content.noMedia = [...(content.noMedia || []), ...payload.value]
      }
    },
    setResponseMedia: (
      state,
      {
        payload,
      }: PayloadAction<{ lessonIndex: number; value: string[]; contentId: number }>,
    ) => {
      const content = state.lessons[payload.lessonIndex].content[payload.contentId]

      if (content.type === "open_ended") {
        content.responseMedia = [...(content.responseMedia || []), ...payload.value]
      }
    },
    setChoiceMedia: (
      state,
      {
        payload,
      }: PayloadAction<{
        lessonIndex: number
        value: string[]
        contentId: number
        choiceId: number
      }>,
    ) => {
      const question = state.lessons[payload.lessonIndex].content[payload.contentId]
      const initQuestion =
        state.initLessons[payload.lessonIndex].content[payload.contentId]

      if (question.type === "multiple" && initQuestion.type === "multiple") {
        const currentChoice = question.choices[payload.choiceId]
        const currentInitChoice = initQuestion.choices[payload.choiceId]
        currentChoice.media = [...(currentChoice.media || []), ...payload.value]
        currentInitChoice.media = [
          ...(currentInitChoice.media || []),
          ...payload.value,
        ]
      }
    },
    setAllChoiceMedia: (
      state,
      {
        payload,
      }: PayloadAction<{ lessonIndex: number; value: string[]; contentId: number }>,
    ) => {
      const question = state.lessons[payload.lessonIndex].content[payload.contentId]
      const initQuestion =
        state.initLessons[payload.lessonIndex].content[payload.contentId]

      if (question.type === "multiple" && initQuestion.type === "multiple") {
        const choices = [...question.choices].map((choice) => ({
          ...choice,
          media: payload.value,
        }))
        const initChoices = [...initQuestion.choices].map((choice) => ({
          ...choice,
          media: payload.value,
        }))
        question.choices = choices
        initQuestion.choices = initChoices
      }
    },
    removeResponseMedia: (
      state,
      {
        payload,
      }: PayloadAction<{ lessonIndex: number; contentId: number; id: number }>,
    ) => {
      const content = state.lessons[payload.lessonIndex].content[payload.contentId]

      if (content.type === "open_ended") {
        ;(
          state.lessons[payload.lessonIndex].content[payload.contentId] as OpenEnded
        ).responseMedia = content.responseMedia?.filter((_, i) => i !== payload.id)
      }
    },
    removeNoResponseMedia: (
      state,
      {
        payload,
      }: PayloadAction<{ lessonIndex: number; contentId: number; id: number }>,
    ) => {
      const content = state.lessons[payload.lessonIndex].content[payload.contentId]

      if (content.type === "yes_no") {
        ;(
          state.lessons[payload.lessonIndex].content[payload.contentId] as YesNo
        ).noMedia = content.noMedia?.filter((_, i) => i !== payload.id)
      }
    },
    removeYesResponseMedia: (
      state,
      {
        payload,
      }: PayloadAction<{ lessonIndex: number; contentId: number; id: number }>,
    ) => {
      const content = state.lessons[payload.lessonIndex].content[payload.contentId]

      if (content.type === "yes_no") {
        ;(
          state.lessons[payload.lessonIndex].content[payload.contentId] as YesNo
        ).yesMedia = content.yesMedia?.filter((_, i) => i !== payload.id)
      }
    },
    removeChoiceMedia: (
      state,
      {
        payload,
      }: PayloadAction<{
        lessonIndex: number
        contentId: number
        id: number
        choiceId: number
      }>,
    ) => {
      const question = state.lessons[payload.lessonIndex].content[payload.contentId]
      const initQuestion =
        state.initLessons[payload.lessonIndex].content[payload.contentId]

      if (question.type === "multiple" && initQuestion.type === "multiple") {
        question.choices[payload.choiceId].media = question.choices[
          payload.choiceId
        ]?.media?.filter((_, i) => i !== payload.id)
        initQuestion.choices[payload.choiceId].media = initQuestion.choices[
          payload.choiceId
        ]?.media?.filter((_, i) => i !== payload.id)
      }
    },
    removeMedia: (
      state,
      {
        payload,
      }: PayloadAction<{ lessonIndex: number; contentId: number; id: number }>,
    ) => {
      state.lessons[payload.lessonIndex].content[payload.contentId].media =
        state.lessons[payload.lessonIndex].content[payload.contentId].media?.filter(
          (_, i) => i !== payload.id,
        )
    },
    setContentQuestionPrompt: (
      state,
      {
        payload,
      }: PayloadAction<{
        lessonIndex: number
        questionIndex: number
        value: string
      }>,
    ) => {
      const question =
        state.lessons[payload.lessonIndex].content[payload.questionIndex]
      if (question.type !== "body") {
        question.questionPrompt = payload.value
      }
    },
    // CONTENT
    setInitialContent: (
      state,
      { payload }: PayloadAction<{ content: IContent[]; lessonIndex: number }>,
    ) => {
      state.initLessons[payload.lessonIndex].content = payload.content
    },
    setContent: (
      state,
      { payload }: PayloadAction<{ content: IContent[]; lessonIndex: number }>,
    ) => {
      state.lessons[payload.lessonIndex].content = payload.content
    },
    addQuestion: (
      state,
      { payload }: PayloadAction<{ question: IContent; lessonIndex: number }>,
    ) => {
      state.lessons[payload.lessonIndex].content.push(payload.question)
      state.initLessons[payload.lessonIndex].content.push(payload.question)
    },
    addBody: (
      state,
      { payload }: PayloadAction<{ body: IBody; lessonIndex: number }>,
    ) => {
      state.lessons[payload.lessonIndex].content.push(payload.body)

      if (state.initLessons[payload.lessonIndex].content) {
        state.initLessons[payload.lessonIndex].content.push(payload.body)
      } else {
        state.initLessons[payload.lessonIndex].content = [payload.body]
      }
    },
    removeContent: (
      state,
      { payload }: PayloadAction<{ lessonIndex: number; contentId: number }>,
    ) => {
      const filtered = state.lessons[payload.lessonIndex].content?.filter(
        (_, i) => i !== payload.contentId,
      )
      state.lessons[payload.lessonIndex].content = filtered
      state.initLessons[payload.lessonIndex].content = filtered
    },
    // MULTIPLE
    setChoiceOption: (
      state,
      {
        payload,
      }: PayloadAction<{
        lessonIndex: number
        questionIndex: number
        choiceIndex: number
        value: string
      }>,
    ) => {
      const question =
        state.lessons[payload.lessonIndex].content[payload.questionIndex]
      if (question.type === "multiple") {
        if (question.choices) {
          question.choices[payload.choiceIndex].option = payload.value
        }
      }
    },
    setChoiceResponse: (
      state,
      {
        payload,
      }: PayloadAction<{
        lessonIndex: number
        questionIndex: number
        choiceIndex: number
        value: string
      }>,
    ) => {
      const question =
        state.lessons[payload.lessonIndex].content[payload.questionIndex]
      if (question.type === "multiple") {
        if (question.choices) {
          question.choices[payload.choiceIndex].response = payload.value
        }
      }
    },
    setChoiceCorrectOption: (
      state,
      {
        payload,
      }: PayloadAction<{
        lessonIndex: number
        questionIndex: number
        value?: number
      }>,
    ) => {
      const question =
        state.lessons[payload.lessonIndex].content[payload.questionIndex]

      if (question.type === "multiple") {
        question.correctOption = payload.value
      }
    },

    addChoice: (
      state,
      {
        payload,
      }: PayloadAction<{
        lessonIndex: number
        questionIndex: number
        choice: IChoice
      }>,
    ) => {
      const question =
        state.lessons[payload.lessonIndex].content[payload.questionIndex]
      const initQuestion =
        state.initLessons[payload.lessonIndex].content[payload.questionIndex]

      if (question.type === "multiple" && initQuestion.type === "multiple") {
        question.choices?.push(payload.choice)
        initQuestion.choices?.push(payload.choice)
      }
    },
    removeChoice: (
      state,
      {
        payload,
      }: PayloadAction<{
        lessonIndex: number
        questionIndex: number
        choiceId: number
      }>,
    ) => {
      const question =
        state.lessons[payload.lessonIndex].content[payload.questionIndex]
      const initQuestion =
        state.initLessons[payload.lessonIndex].content[payload.questionIndex]

      if (question.type === "multiple" && initQuestion.type === "multiple") {
        question.choices = question.choices.filter((_, i) => i !== payload.choiceId)
        initQuestion.choices = initQuestion.choices.filter(
          (_, i) => i !== payload.choiceId,
        )
      }
    },
    // BODY
    setContentText: (
      state,
      {
        payload,
      }: PayloadAction<{ lessonIndex: number; bodyIndex: number; value: string }>,
    ) => {
      const content = state.lessons[payload.lessonIndex].content[payload.bodyIndex]
      if (content.type === "body") {
        content.text = payload.value
      }
    },
    setContentResponse: (
      state,
      {
        payload,
      }: PayloadAction<{
        lessonIndex: number
        questionIndex: number
        value: string
      }>,
    ) => {
      const content =
        state.lessons[payload.lessonIndex].content[payload.questionIndex]
      if (content.type === "open_ended") {
        content.response = payload.value
      }
    },
    setContentResponseYes: (
      state,
      {
        payload,
      }: PayloadAction<{
        lessonIndex: number
        questionIndex: number
        value: string
      }>,
    ) => {
      const content =
        state.lessons[payload.lessonIndex].content[payload.questionIndex]
      if (content.type === "yes_no") {
        content.yesResp = payload.value
      }
    },
    setContentResponseNo: (
      state,
      {
        payload,
      }: PayloadAction<{
        lessonIndex: number
        questionIndex: number
        value: string
      }>,
    ) => {
      const content =
        state.lessons[payload.lessonIndex].content[payload.questionIndex]
      if (content.type === "yes_no") {
        content.noResp = payload.value
      }
    },
  },
})

export const lessonsActions = lessonsSlice.actions

export default lessonsSlice.reducer
