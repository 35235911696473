import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState = {
  show: false,
  title: "",
  content: "",
}

export const confirmSlice = createSlice({
  name: "confirm",
  initialState,
  reducers: {
    showConfirm: (state, action: PayloadAction<{ title: string; content: string }>) => {
      state.show = true
      state.title = action.payload.title
      state.content = action.payload.content
    },
    hideConfirm: (state) => {
      state.show = false
      state.title = ""
      state.content = ""
    },
  },
})

export const { showConfirm, hideConfirm } = confirmSlice.actions

export default confirmSlice.reducer
