import { H2, H5 } from "~/components/common/Headings"
import { useTranslation } from "react-i18next"
import Modal from "~/components/common/Modal"
import useAppSelector from "~/hooks/useAppSelector"
import { CrownIcon } from "~/assets/svg"

import Button from "~/components/common/Button"

const SuccessSub = ({
  open,
  handleClose,
}: {
  open: boolean
  handleClose: () => void
}) => {
  const { currentPlan } = useAppSelector((state) => state.premium)
  const name = useAppSelector((state) => state.user.name)

  const { t } = useTranslation() as any

  return (
    <Modal handleClose={handleClose} open={open}>
      <CrownIcon />
      <H2 mb="18px">
        {t("success_sub", { plan: currentPlan, name: name || "Guest" })}
      </H2>
      <H5 mb="15px">{t("success_sub_subtitle")}</H5>

      <Button>{t("go_to_sub")}</Button>
    </Modal>
  )
}

export default SuccessSub
