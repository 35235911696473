import { PlusIcon } from "~/assets/svg"
import Drawer from "~/components/Drawer"
import Navbar from "~/components/common/Header"
import Main from "~/components/Main"
import Row, { IRowProps } from "~/components/common/Row"
import { Box, IconButton } from "@mui/material"
import React, { useState } from "react"

interface PageWithDrawerProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {}

const PageWithDrawer = ({ children }: PageWithDrawerProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const openDrawer = () => {
    setIsDrawerOpen(true)
  }

  return (
    <React.Fragment key="drawer">
      <Navbar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
      <Drawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
      <Main>{children}</Main>
    </React.Fragment>
  )
}

export default PageWithDrawer
