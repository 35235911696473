import MUITextField, { TextFieldProps } from "@mui/material/TextField"
import MUISelect, { SelectProps } from "@mui/material/Select"
import classNames from "classnames"
import { forwardRef } from "react"

export const TextField = forwardRef((props: TextFieldProps, ref: any) => {
  return (
    <MUITextField
      {...props}
      ref={ref}
      className={classNames(props.className, "ls-muiInput")}
    />
  )
})

TextField.displayName = "TextField"

export const Select = forwardRef((props: SelectProps, ref: any) => {
  return (
    <MUISelect
      {...props}
      ref={ref}
      className={classNames(props.className, "ls-muiInput")}
    />
  )
})

Select.displayName = "TextField"

// export const Select = styled(MUISelect)(({ theme }: { theme: Theme }) => ({
//   position: "relative",

//   "& .MuiSelect-icon": {
//     top: "inherit",
//   },
//   "& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
//     borderColor: "var(--grey-2)",
//   },
//   "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
//     borderColor: theme.palette.primary.main,
//   },

//   "& .Mui-focused:hover .MuiOutlinedInput-notchedOutline": {
//     borderColor: theme.palette.primary.main,
//   },
//   "& .Mui-error .MuiOutlinedInput-notchedOutline": {
//     borderColor: theme.palette.error.main,
//   },
//   "& .Mui-error:hover .MuiOutlinedInput-notchedOutline": {
//     borderColor: theme.palette.error.main,
//   },
//   "& .MuiOutlinedInput-notchedOutline": {
//     borderRadius: "8px",
//     borderColor: "var(--grey-2)",
//   },
// }))
