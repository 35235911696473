import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: (code) => {
      if (!code || code.startsWith("ru")) return ["ru"]
      if (!code || code.startsWith("en")) return ["ru"]
      if (!code || code.startsWith("uz")) return ["uz"]

      return ["ru"]
    },
    resources: {
      ru: {
        translations: require("./locales/ru/translations.json"),
      },
      uz: {
        translations: require("./locales/uz/translations.json"),
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  })

i18n.languages = ["ru", "uz"]

export default i18n
