import React, { useCallback, useState } from "react"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Button as MUIButton, Grid } from "@mui/material"

import Row from "~/components/common/Row"
import AUTH_API from "~/api/auth"
import useAppSelector from "~/hooks/useAppSelector"
import { LogoIcon, TimerIcon, UpdateIcon } from "~/assets/svg"
import { CLASSROOM_CREATE } from "~/constants/routes"
import { Footer, Logo } from "~/pages/SignIn"
import { P3, P5 } from "~/components/common/Paragraph"
import useAppDispatch from "~/hooks/useAppDispatch"
import { clearAuth } from "~/context/slices/auth"
import { Code, Codes } from "~/components/Code"
import InlineBox from "~/components/common/InlineBox"
import Timer from "~/components/Timer"

import img_ru from "~/assets/imgs/login_ru.jpg"
import img_en from "~/assets/imgs/login_en.jpg"
import img_uz from "~/assets/imgs/login_uz.jpg"
import showError from "~/utils/toast/showError"

export interface ILocationState {
  email: string
  isSignUp: boolean
  verifyToken: string
}

const Otp = () => {
  const { error, loading } = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const {
    t,
    i18n: { language },
  } = useTranslation() as any

  const [code, setCode] = useState<string[]>(["", "", "", "", "", ""])
  const [focusedIndex, setFocusedIndex] = useState<number>()

  const email = (location.state as ILocationState)?.email
  const verifyToken = (location.state as ILocationState)?.verifyToken

  const inputs = React.useRef<any>(
    Array(6)
      .fill("")
      .map(() => React.createRef() as any),
  )

  const [isOTPExpired, setIsOTPExpired] = useState(false)

  const [seconds, setSeconds] = useState<number>()
  const [minutes, setMinutes] = useState<number>()

  const handleOtp = async () => {
    if (!email) {
      dispatch(clearAuth())
      return
    }
    try {
      let res = await AUTH_API.login(email)

      if (res.status < 200 && res.status >= 300) {
        return
      }
    } catch (err) {
      showError(err)
    }
  }

  const handleCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.match(/\d+/gi)?.join("") || ""

    if (!focusedIndex && focusedIndex !== 0) return
    if (focusedIndex === 5 && e.target.value.length > 1) {
      return
    }
    let newCode = [...code]

    newCode[focusedIndex] = value
    setCode(newCode)

    if (value.length) {
      if (focusedIndex + 1 < 6) {
        inputs.current[focusedIndex + 1].current?.focus()
      }
    } else {
      if (focusedIndex > 0) {
        inputs.current[focusedIndex - 1].current?.focus()
      }
    }
  }

  const handleCodeOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "e") return

    if (!focusedIndex) return

    if (inputs.current[focusedIndex].current?.value.length === 0) {
      if (e.key === "Backspace" || e.keyCode === 8) {
        inputs.current[focusedIndex - 1].current?.focus()
        e.preventDefault()
      }
    }
  }

  const clearInputs = () => {
    inputs.current?.map((input: any, i: number) => {
      input.current.value = ""
    })
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const fromClipboard = e.clipboardData.getData("text").slice(0, 6)
    const c = fromClipboard.split("")
    inputs.current.map((input: any, i: number) => {
      input.current.value = c[i]
    })
    setCode(c)
  }

  const onEnd = useCallback(() => {
    setIsOTPExpired(true)
  }, [])

  const focus = (index: number) => {
    return (e?: React.FocusEvent<HTMLInputElement>) => {
      setFocusedIndex(index)
      inputs.current[index]?.current?.select()
    }
  }

  const sendOTP = async (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (code.join("").length >= 6) {
      const email = localStorage.getItem("@email")
      if (email === null) {
        return
      }
      try {
        const res = await AUTH_API.otp({
          email,
          otc: code.join("").slice(0, 6),
          type: "verifyEmail",
        })

        localStorage.setItem("@actionToken", res.data.actionToken)
        if (res.data.actionToken) {
          navigate(CLASSROOM_CREATE)
        }
      } catch (err) {
        showError(err)
        setCode(["", "", "", "", "", ""])
        clearInputs()
        focus(0)()
      }
    }
  }

  const checkOTPisExpires = useCallback(() => {
    const expiresIn = JSON.parse(localStorage.getItem("@expiresIn") || "0")
    const datenow = new Date()

    const expiresInDate = new Date(expiresIn)

    if (!expiresIn) {
      if (expiresInDate.getTime() < datenow.getTime()) {
        localStorage.removeItem("@expiresIn")
      }
    }

    if (expiresInDate.getTime() > datenow.getTime()) {
      setIsOTPExpired(false)

      const m = expiresInDate.getMinutes() - datenow.getMinutes()
      const s = expiresInDate.getSeconds() - datenow.getSeconds()
      if (s !== 0) {
        if (m - 1 > 0) {
          setMinutes(m - 1)
        } else {
          setMinutes(0)
        }
      } else {
        setMinutes(m)
      }
      setSeconds(s < 0 ? 60 + s : s)
    } else {
      setIsOTPExpired(true)
    }
  }, [])

  React.useEffect(() => {
    if (code.join("").length === 6) {
      sendOTP()
    }
  }, [code])

  React.useEffect(() => {
    checkOTPisExpires()

    return () => {
      dispatch(clearAuth())
      localStorage.removeItem("@timer")
    }
  }, [])

  return (
    <Grid className={"ls-auth__main"} container>
      <Grid
        xs={12}
        sm={7}
        md={6}
        item
        className={classNames("ls-auth__leftContainer")}>
        <Logo>
          <LogoIcon />
        </Logo>

        <InlineBox w="350px" maxWidth="95%">
          <P3 textAlign="center" mb="20px">
            {t("enter_password")}
            <br />
            {email ? <strong>({email})</strong> : null}
          </P3>

          <InlineBox className="text-center" mb="20px">
            {isOTPExpired ? (
              <MUIButton startIcon={<UpdateIcon />} onClick={handleOtp}>
                {t("resend_code")}
              </MUIButton>
            ) : (
              <Row justify="center" align="center">
                <TimerIcon />
                <InlineBox mr="4px" />
                {(seconds || seconds === 0) && (minutes || minutes === 0) ? (
                  <P3 textAlign="center">
                    {t("otp_expires_in")}{" "}
                    <Timer
                      onEnd={onEnd}
                      initialMinute={minutes}
                      initialSeconds={seconds}
                    />
                  </P3>
                ) : null}
              </Row>
            )}
          </InlineBox>
          <Codes className="codes mb-20">
            {Array(6)
              .fill("")
              .map((_, index) => (
                <Code
                  ref={inputs.current[index]}
                  onFocus={focus(index)}
                  onPaste={handlePaste}
                  maxLength={1}
                  // type="number"
                  autoFocus={index === 0}
                  onBlur={() => setFocusedIndex(undefined)}
                  onKeyDown={handleCodeOnKeyDown}
                  key={`code_${index}`}
                  onChange={handleCode}
                  value={code[index]}
                  className={classNames({
                    error: error,
                  })}
                />
              ))}
          </Codes>
          {/* <Button
            className={"w-100"}
            disabled={code.join("").length < 6}
            onClick={sendOTP}
            type="submit">
            {loading ? (
              <CircularProgress
                size={20}
                sx={{
                  color: "var(--white)",
                }}
              />
            ) : null}
            {t("authorize")}
          </Button> */}
        </InlineBox>

        <Footer>
          <P5>
            © 2022{" "}
            <strong>
              <Link to="/">Lessenger</Link>
            </strong>{" "}
            {t("all_rights_reserved")}
          </P5>
        </Footer>
      </Grid>
      <Grid
        xs={12}
        sm={5}
        md={6}
        className={"ls-auth__rightContainer"}
        sx={{
          backgroundImage: `url(${
            language === "en" ? img_en : language === "uz" ? img_uz : img_ru
          })`,
        }}
        item
      />
    </Grid>
  )
}

export default Otp
