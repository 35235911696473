import AUTH_API, { ISignInRequestBody } from "~/api/auth"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { setToken } from "./activate"

export const login = createAsyncThunk("auth/login", async (body: ISignInRequestBody, thunkAPI) => {
  try {
    const response = await AUTH_API.signIn(body)

    if (response.data.token) {
      setToken(response.data.token)
    }
    return response.data
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err?.response?.data?.message || err?.message || "SignIn error")
  }
})

export default login
