import React, { Suspense, useEffect } from "react"
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom"

import Dashboard from "~/pages/Dashboard"
import {
  CLASSROOM_CREATE,
  DASHBOARD,
  FORGOT_PASSWD,
  FORGOT_PASSWD_OTP,
  OTP,
  RESET_PASSWD,
  SIGN_IN,
  SIGN_UP,
} from "~/constants/routes"
import useAppDispatch from "~/hooks/useAppDispatch"
import Loader from "~/components/Loader"

import useAppSelector from "~/hooks/useAppSelector"
import { authenticate, logout } from "~/context/slices/auth"
import SignIn from "./pages/SignIn"
import { ROUTES } from "routes"
import { clearUser, setUser } from "./context/slices/user"
import {
  makeMainCard,
  setAllCards,
  setAutoRenewal,
  setCurrentPlan,
  setInitialPremium,
} from "./context/slices/premium"
import SignUp from "./pages/SignUp"
import ForgotPassword from "./pages/ForgotPassword"
import ResetPasswordOtp from "./pages/ForgotPassword/Otp"
import ResetPassword from "./pages/ResetPassword"
import AUTHOR_API from "./api/author"
import { getCategories } from "./api/categories"
import { useTranslation } from "react-i18next"
import { setAllCategories } from "./context/slices/cats"
import { EventSourcePolyfill } from "event-source-polyfill"
import envs from "./config/env"
import { clearSSEData, sendData } from "./context/slices/sse"
import showError from "./utils/toast/showError"
import ClassroomCreate from "./pages/ClassroomCreate"
import Otp from "./pages/Otp"

function App() {
  let sse: EventSource
  const { isLoggedIn, role } = useAppSelector((state) => state.auth)
  const { i18n } = useTranslation() as any

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const tryToConnect = () => {
    try {
      const token = localStorage.getItem("@token")
      sse = new EventSourcePolyfill(`${envs.BACKEND_URL}/dash/events`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "text/event-stream",
          "Cache-Control": "no-cache",
          Connection: "keep-alive",
          "X-Accel-Buffering": "no",
        },
        heartbeatTimeout: 1000 * 60 * 60,
      })
      sse.onmessage = (e) => {
        dispatch(sendData(e.data))
      }
    } catch (err) {}
  }

  const initCategories = async () => {
    try {
      const res = await getCategories(i18n.language.slice(0, 2))
      const cats = [...res.data.cats].sort((a: any, b: any) =>
        a.data.title > b.data.title ? 1 : -1,
      )

      dispatch(
        setAllCategories(
          cats.map((cat: any) => ({
            value: cat.data.slug,
            name: cat.data.title,
          })),
        ),
      )
    } catch (err) {}
  }

  const checkAuth = async () => {
    try {
      const token = localStorage.getItem("@token")
      const i18nextLng = localStorage.getItem("i18nextLng")
      const user = JSON.parse(localStorage.getItem("@user") || "{}")

      if (!i18n.languages.includes(i18nextLng)) {
        if (i18n.languages.includes(i18nextLng?.slice(0, 2))) {
          i18n.changeLanguage(i18nextLng?.slice(0, 2))
        } else {
          i18n.changeLanguage("ru")
        }
      }

      if (token) {
        if (!user.domain) {
          navigate(CLASSROOM_CREATE, {
            replace: true,
          })
          dispatch(clearUser())
          dispatch(logout())
          dispatch(setInitialPremium())
        } else {
          const res = await AUTHOR_API.getPro()

          if (res.data.author.wallets?.length) {
            dispatch(
              setAllCards(
                res.data.author.wallets.map((wallet: any) => ({
                  cardNumber: wallet.number,
                  cardExpired: wallet.expire,
                  cardToken: wallet.token,
                })),
              ),
            )

            dispatch(makeMainCard(res.data.author.mainWallet || 0))
          }
          if (res.data.author.proPlan?.createdAt) {
            const now = Date.now()
            const createdAt = new Date(res.data.author.proPlan?.createdAt!).getTime()
            const expiresIn = new Date(res.data.author.proPlan?.expiresIn!).getTime()

            if (now > createdAt && now < expiresIn) {
              dispatch(setCurrentPlan("pro"))
              dispatch(setAutoRenewal(res.data.author.proPlan?.autoRenewal!))
            }
          }

          dispatch(setUser({ ...user, proPlan: res.data.author.proPlan }))
          dispatch(authenticate({ token, me: user }))
        }
      } else {
        dispatch(clearUser())
        dispatch(logout())
        dispatch(setInitialPremium())
      }
    } catch (err: any) {
      showError(err)
      if (err.response?.status === 444 || err.response?.status === 401) {
        dispatch(clearUser())
        dispatch(logout())
        dispatch(setInitialPremium())
      }
    }
  }

  useEffect(() => {
    checkAuth()

    return () => {
      sse?.close()
      dispatch(clearSSEData())
    }
  }, [])

  useEffect(() => {
    initCategories()
  }, [i18n.language])

  useEffect(() => {
    if (isLoggedIn) {
      tryToConnect()
    }
    return () => {
      sse?.close()
    }
  }, [isLoggedIn])

  return (
    <div className="App">
      <Routes>
        {isLoggedIn !== null ? (
          isLoggedIn ? (
            <>
              <Route path={DASHBOARD} element={<Dashboard />} />
              <Route path="*" element={<Navigate to={DASHBOARD} replace />} />

              <Route
                path="/"
                element={
                  <Suspense fallback={<Loader />}>
                    <Outlet />
                  </Suspense>
                }>
                {ROUTES.map((route) => {
                  if (route.permission.includes(role)) {
                    return (
                      <Route
                        key={route.path}
                        path={`${route.path}`}
                        element={route.component}
                      />
                    )
                  }
                })}
              </Route>
            </>
          ) : (
            <>
              <Route path={SIGN_IN} element={<SignIn />} />
              <Route path={SIGN_UP} element={<SignUp />} />
              <Route path={FORGOT_PASSWD} element={<ForgotPassword />} />
              <Route path={RESET_PASSWD} element={<ResetPassword />} />
              <Route path={FORGOT_PASSWD_OTP} element={<ResetPasswordOtp />} />
              <Route path={CLASSROOM_CREATE} element={<ClassroomCreate />} />
              <Route path={OTP} element={<Otp />} />

              <Route path="*" element={<Navigate to={SIGN_IN} replace />} />
              <Route path="/" element={<Navigate to={SIGN_IN} replace />} />
            </>
          )
        ) : (
          <Route path="*" element={<Loader />} />
        )}
      </Routes>
    </div>
  )
}

export default App
