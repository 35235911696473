import { useState } from "react"
import { useTranslation } from "react-i18next"
import { CircularProgress, Grid, IconButton, InputAdornment } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"

import AUTH_API from "~/api/auth"
import classNames from "classnames"
import Button from "~/components/common/Button"
import { H2 } from "~/components/common/Headings"
import { P3, P5 } from "~/components/common/Paragraph"
import { EyeCloseIcon, EyeOpenIcon, LogoIcon } from "~/assets/svg"
import { TextField } from "~/components/common/TextField"
import InlineBox from "~/components/common/InlineBox"
import { SubmitHandler, useForm } from "react-hook-form"
import { Footer, Logo } from "../SignIn"
import useAppDispatch from "~/hooks/useAppDispatch"
import {
  makeMainCard,
  setAllCards,
  setAutoRenewal,
  setCurrentPlan,
} from "~/context/slices/premium"
import { authenticate } from "~/context/slices/auth"
import { setUser } from "~/context/slices/user"

import img_ru from "~/assets/imgs/login_ru.jpg"
import img_en from "~/assets/imgs/login_en.jpg"
import img_uz from "~/assets/imgs/login_uz.jpg"
import showError from "~/utils/toast/showError"

type Inputs = {
  password: string
  password_confirmation: string
}

const ResetPassword = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState(true)

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>()

  const {
    t,
    i18n: { language },
  } = useTranslation() as any

  const toggleVisibilityPassword = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const resetToken = localStorage.getItem("@actionToken")
      if (resetToken === null) {
        return
      }
      setLoading(true)
      const res = await AUTH_API.resetPassword({
        newPasswd: data.password,
        resetToken,
      })

      if (res.data.ok === -1 && res.data.reason === "noDomain") {
        navigate("/otp")
      } else {
        const me = res.data.me
        const token = res.data.token

        if (me.wallets?.length) {
          dispatch(
            setAllCards(
              me.wallets.map((wallet: any) => ({
                cardNumber: wallet.number,
                cardExpired: wallet.expire,
                cardToken: wallet.token,
              })),
            ),
          )

          dispatch(makeMainCard(me.mainWallet || 0))
        }
        if (me.proPlan?.createdAt) {
          const now = Date.now()
          const createdAt = new Date(me.proPlan?.createdAt).getTime()
          const expiresIn = new Date(me.proPlan?.expiresIn).getTime()

          if (now > createdAt && now < expiresIn) {
            dispatch(setCurrentPlan("pro"))
            dispatch(setAutoRenewal(me.proPlan?.autoRenewal))
          }
        }

        dispatch(setUser({ ...me, proPlan: me.proPlan }))
        dispatch(authenticate({ token, me }))
      }
    } catch (err: any) {
      showError(err)
    }
  }

  return (
    <Grid className={"ls-auth__main"} container>
      <Grid
        xs={12}
        sm={7}
        md={6}
        item
        className={classNames("ls-auth__leftContainer")}>
        <Logo>
          <LogoIcon />
        </Logo>

        <InlineBox w="350px" maxWidth="95%" mb="20px">
          <H2 textAlign="center" mb="20px">
            {t("reset_password")}
          </H2>
          <P3 textAlign="center" mb="30px">
            {t("reset_password_subtitle")}
          </P3>

          <form onSubmit={handleSubmit(onSubmit)} className="mb-20">
            <InlineBox mb="20px">
              <TextField
                variant="outlined"
                label={t("password")}
                error={!!errors.password?.type}
                helperText={errors.password?.message}
                id="password"
                type={isPasswordVisible ? "password" : "text"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleVisibilityPassword}>
                        {isPasswordVisible ? (
                          <EyeOpenIcon
                            width="20"
                            height="15"
                            fill="var(--new-grey)"
                          />
                        ) : (
                          <EyeCloseIcon
                            width="20"
                            height="15"
                            fill="var(--new-grey)"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register("password", {
                  required: true,
                  minLength: 8,
                })}
                autoComplete="off"
                fullWidth
                placeholder="******"
              />
            </InlineBox>
            <InlineBox mb="20px">
              <TextField
                variant="outlined"
                label={t("password_confirmation")}
                error={!!errors.password_confirmation?.type}
                helperText={errors.password?.message}
                id="password_confirm"
                fullWidth
                type={isPasswordVisible ? "password" : "text"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleVisibilityPassword}>
                        {isPasswordVisible ? (
                          <EyeOpenIcon
                            width="20"
                            height="15"
                            fill="var(--new-grey)"
                          />
                        ) : (
                          <EyeCloseIcon
                            width="20"
                            height="15"
                            fill="var(--new-grey)"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register("password_confirmation", {
                  required: true,
                  minLength: 8,
                })}
                autoComplete="off"
                placeholder="******"
              />
            </InlineBox>{" "}
            <Button className={"w-100 brsr-extra"} disabled={loading} type="submit">
              {loading ? (
                <CircularProgress
                  size={20}
                  sx={{
                    color: "var(--white)",
                  }}
                />
              ) : (
                t("submit")
              )}
            </Button>
          </form>
        </InlineBox>

        <Footer>
          <P5 textAlign="center">
            © 2022{" "}
            <strong>
              <Link to="/">Lessenger</Link>
            </strong>{" "}
            {t("all_rights_reserved")}
          </P5>
        </Footer>
      </Grid>
      <Grid
        xs={12}
        sm={5}
        md={6}
        className={"ls-auth__rightContainer"}
        sx={{
          backgroundImage: `url(${
            language === "en" ? img_en : language === "uz" ? img_uz : img_ru
          })`,
        }}
        item
      />
    </Grid>
  )
}

export default ResetPassword
