import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import useTable from "~/hooks/useTable"
import TableRow from "./TableRow"
import { dateFromObjectId } from "~/utils/mongodbId"
import { H5 } from "~/components/common/Headings"

const Table = ({ data, rowsPerPage: str }: { data: any[]; rowsPerPage: number }) => {
  const [rowsPerPage] = useState(str)
  const [page] = useState(1)

  const [filteredData, setFilteredData] = useState(data)
  const { t } = useTranslation() as any

  const { slice } = useTable(filteredData, page, rowsPerPage)

  React.useEffect(() => {
    setFilteredData(data)
  }, [data])

  return (
    <div>
      <table className={"ls-table"}>
        <thead>
          <tr className={"ls-table__head-row"}>
            <th className={"ls-table__head-cell"} scope="col">
              <H5 flex={1} color="var(--new-grey)">
                {t("name")}
              </H5>
            </th>
            <th className={"ls-table__head-cell"} scope="col">
              <H5 color="var(--new-grey)">{t("date_of_registration")}</H5>
            </th>
            <th className={"ls-table__head-cell"} scope="col">
              <H5 color="var(--new-grey)">{t("status")}</H5>
            </th>
            <th className={"ls-table__head-cell"} scope="col">
              <H5 color="var(--new-grey)">{t("course")}</H5>
            </th>
            <th className={"ls-table__head-cell"} scope="col">
              <H5 color="var(--new-grey)">{t("price")}</H5>
            </th>
            <th className={"ls-table__head-cell"} scope="col">
              <H5 color="var(--new-grey)">{t("payment_method")}</H5>
            </th>
          </tr>
        </thead>
        {slice.length ? (
          <tbody>
            {slice?.map((row, index) => (
              <TableRow
                name={row.fullName || row.learner}
                dateOfReg={row.createdAt}
                status={row.status || ""}
                courseName={row.topicTitle || ""}
                price={row.amount || 0}
                paymentMethod={row.paymentMethod ? row.paymentMethod[0] || "-" : "-"}
                key={row?.id}
              />
            ))}
          </tbody>
        ) : null}
      </table>
    </div>
  )
}

export default Table
