import React from "react"
import {
  Backdrop,
  Fade,
  IconButton,
  Modal as MUIModal,
  ModalProps,
} from "@mui/material"
import { CloseIcon } from "~/assets/svg"
import classNames from "classnames"
import InlineBox from "../InlineBox"

interface Props extends ModalProps<any, any> {
  handleClose: () => void
  open: boolean
  children?: React.ReactNode
  hideCloseBtn?: boolean
  className?: string
}

const Modal = ({
  handleClose,
  open,
  children,
  className,
  hideCloseBtn = false,
  ...props
}: Props) => {
  return (
    <MUIModal
      disableScrollLock
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 150,
      }}
      {...props}>
      <Fade in={open}>
        <InlineBox className={classNames("ls-modal__container", className)}>
          {!hideCloseBtn ? (
            <InlineBox className="ls-modal__closeBtn">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </InlineBox>
          ) : null}

          {children}
        </InlineBox>
      </Fade>
    </MUIModal>
  )
}

export default Modal
