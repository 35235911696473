import { ICard } from "~/types/payment"
import { Plan } from "~/types/premium"

export interface PremiumInitialState {
  bill: "monthly" | "annualy"
  currentPlan: "trial" | "pro"
  cards: ICard[]
  autoRenewal?: boolean
  status: "active" | "suspended"
  paymentMethod: "payme" | "click" | null
  plan?: Plan
  mainWallet?: number
}

export const premiumInitialStates: PremiumInitialState = {
  bill: "monthly",
  currentPlan: "trial",
  status: "active",
  cards: [],

  paymentMethod: "payme",
}

export default premiumInitialStates
