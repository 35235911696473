import { Switch, SwitchProps } from "@mui/material"
import { forwardRef } from "react"
import classNames from "classnames"

export const IOSSwitch = forwardRef((props: SwitchProps, ref: any) => {
  return (
    <Switch
      {...props}
      ref={ref}
      focusVisibleClassName=".Mui-focusVisible" disableRipple
      className={classNames(props.className, "ls-muiSwitch ios")}
    />
  )
})
IOSSwitch.displayName = "IOSSwitch"


export const BillSwitch = forwardRef((props: SwitchProps, ref: any) => {
  return (
    <Switch
      {...props}
      ref={ref}
      focusVisibleClassName=".Mui-focusVisible" disableRipple
      className={classNames(props.className, "ls-muiSwitch bill")}
    />
  )
})
BillSwitch.displayName = "BillSwitch"


export const PrimarySwitch = forwardRef((props: SwitchProps, ref: any) => {
  return (
    <Switch
      {...props}
      ref={ref}
      focusVisibleClassName=".Mui-focusVisible" disableRipple
      className={classNames(props.className, "ls-muiSwitch primary")}
    />
  )
})
PrimarySwitch.displayName = "PrimarySwitch"


export const PrimaryProSwitch = forwardRef((props: SwitchProps, ref: any) => {
  return (
    <Switch
      {...props}
      ref={ref}
      focusVisibleClassName=".Mui-focusVisible" disableRipple
      className={classNames(props.className, "ls-muiSwitch primary-pro")}
    />
  )
})
PrimaryProSwitch.displayName = "PrimaryProSwitch"

export default IOSSwitch
