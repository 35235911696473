import styled from "styled-components"

import "./styles.scss"

interface IMainProps extends React.HTMLAttributes<HTMLDivElement> {}

const MainStyled = styled.main`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  position: relative;
  padding: 30px 20px 20px 85px;
  min-height: calc(100vh - 60px);
  overflow-x: hidden;

  &.courseDetails {
    padding: 30px 0px 20px 85px;

    @media (max-width: 900px) {
      padding-right: 16px;
    }
    @media (max-width: 767px) {
      padding-left: 20px;
    }
  }

  @media (max-width: 767px) {
    padding-left: 20px;
  }
`

const Main = ({ children, ...props }: IMainProps) => {
  return <MainStyled {...props}>{children}</MainStyled>
}

export default Main
