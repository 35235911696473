import styled, { css } from "styled-components"

type PropsButton = {
  border?: string
  radius?: string
  p?: string
  px?: string
  py?: string
  pr?: string
  pl?: string
  pb?: string
  pt?: string

  m?: string
  mx?: string
  my?: string
  mr?: string
  ml?: string
  mb?: string
  mt?: string
  minWidth?: string
  prefixIcon?: boolean
  suffixIcon?: boolean
}

export const SimpleButton = styled.button<PropsButton>`
  line-height: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-family: var(--font);
  text-transform: inherit;
  transition: var(--speed);
  color: var(--dark);
  background-color: var(--white);
  transform: scale(1);
  font-weight: 500;
  position: relative;
  z-index: 0;

  ${(props) =>
    css`
      border: ${props.border || "1px solid #200e3233"};
    `}
  ${(props) =>
    css`
      border-radius: ${props.radius || "8px"};
    `}
  ${(props) =>
    props.prefixIcon &&
    css`
      align-items: center;
      justify-content: center;

      & > svg {
        height: 19px;
        width: 19px;
        margin-right: 12px;
      }
    `}
  ${(props) =>
    props.suffixIcon &&
    css`
      align-items: center;
      justify-content: center;

      svg {
        height: 19px;
        width: 19px;
        margin-left: 12px;
      }
    `}
  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth || "0px"};
    `}
  ${(props) =>
    css`
      padding: ${props.p || "10px 15px"};
    `}
  ${(props) =>
    props.px &&
    css`
      padding-left: ${props.px || "15px"};
      padding-right: ${props.px || "15px"};
    `}
  ${(props) =>
    props.py &&
    css`
      padding-top: ${props.py || "10px"};
      padding-bottom: ${props.py || "10px"};
    `}
  ${(props) =>
    props.pr &&
    css`
      padding-right: ${props.pr || "15px"};
    `}
  ${(props) =>
    props.pl &&
    css`
      padding-left: ${props.pl || "15px"};
    `}
  ${(props) =>
    props.pt &&
    css`
      padding-top: ${props.pt || "10px"};
    `}
  ${(props) =>
    props.pb &&
    css`
      padding-bottom: ${props.pb || "10px"};
    `}
  ${(props) =>
    props.m &&
    css`
      margin: ${props.m || "0"};
    `}
  ${(props) =>
    props.mx &&
    css`
      margin-left: ${props.mx || "0"};
      margin-right: ${props.mx || "0"};
    `}
  ${(props) =>
    props.my &&
    css`
      margin-top: ${props.my || "0"};
      margin-bottom: ${props.my || "0"};
    `}
  ${(props) =>
    props.mr &&
    css`
      margin-right: ${props.mr || "0"};
    `}
  ${(props) =>
    props.ml &&
    css`
      margin-left: ${props.ml || "0"};
    `}
  ${(props) =>
    props.mt &&
    css`
      margin-top: ${props.mt || "0"};
    `}
  ${(props) =>
    props.mb &&
    css`
      margin-bottom: ${props.mb || "0"};
    `}

  &.danger {
    color: var(--white);
    border-color: var(--red);
    background-color: var(--red);

    &:hover {
      border-color: var(--red);
      color: var(--red);
      background-color: var(--white);

      svg {
        * {
          fill: var(--red);
        }
      }
    }
  }
  
  &.danger-icon {
    &:hover{
      background-color: var(--white);
      
      
      p {
        color: var(--new-dark);
      }
      
      svg{
        * {
          stroke: var(--red);
        }
      }
    }
  }

  & > svg {
    &.MuiCircularProgress-svg {
      margin-right: 0;
    }
  }

  &.w-100 {
    width: 100%;
  }

  &:disabled {
    opacity: 0.6;
    &:hover {
      cursor: not-allowed;
    }
  }

  &.MuiButton-root::before {
    position: absolute;
    top: 5px;
    left: 5px;
    bottom: -5px;
    right: 5px;
    z-index: -1;
    transition: var(--speed);
    opacity: 0;
    filter: blur(25px);
    content: "sdf";

    border-radius: 28px;
    background-image: linear-gradient(0deg, #000 0%, var(--white) 66.15%);
  }

  &:active {
    border-color: transparent;
  }

  &:hover {
    background-color: var(--background);
    cursor: pointer;
    &::before {
      opacity: 0.3;
    }
  }
`

export const PrimaryButton = styled(SimpleButton)`
  border-color: transparent;
  background-color: var(--primary);
  color: var(--white);
  position: relative;

  ${(props) =>
    props.p &&
    css`
      padding: ${props.p || "12px 42px"};
    `}
  ${(props) =>
    props.radius &&
    css`
      border-radius: ${props.radius || "28px"};
    `}
  border: 2px solid transparent;
  z-index: 1;

  &.Mui-disabled {
    color: var(--white);
    opacity: 0.5;
    pointer-events: all;

    &:hover {
      cursor: no-drop;
    }
  }

  &.fill {
    svg {
      * {
        fill: currentColor;
      }
    }
  }
  &.stroke {
    svg {
      * {
        stroke: currentColor;
      }
    }
  }

  &.icon-hover {
    &:hover {
      &.fill {
        svg {
          * {
            fill: var(--primary);
          }
        }
      }
      &.stroke {
        svg {
          * {
            stroke: var(--primary);
          }
        }
      }
    }
  }
  &:hover {
    background-color: transparent;
    color: var(--primary);
    border-color: var(--primary);

    &::before {
      opacity: 0.3;
    }
  }
`

export const SuccessButton = styled(SimpleButton)`
  background-image: linear-gradient(180deg, #a9e47d 0%, #4dcb9d 100%);
  color: var(--white);
  position: relative;
  padding: 12px 22px;
  /* border-radius: 28px; */
  border: none;
  z-index: 1;

  &.Mui-disabled {
    color: var(--white);
    opacity: 0.5;
    pointer-events: all;

    &:hover {
      cursor: no-drop;
    }
  }

  &::before {
    position: absolute;
    top: 5px;
    left: 5px;
    bottom: -5px;
    right: 5px;
    z-index: -1;
    transition: var(--speed);
    opacity: 0.4;
    filter: blur(20px);
    content: "";

    border-radius: 28px;
    background-image: linear-gradient(180deg, #a9e47d 0%, #4dcb9d 100%);
  }

  &:hover {
    background-color: transparent;

    &::before {
      opacity: 1;
    }
  }
`

const Button = styled(SimpleButton)`
  border: none;
  background-image: linear-gradient(0deg, #7557fe 0%, #658fff 100%);
  color: var(--white);
  position: relative;
  z-index: 1;

  &.Mui-disabled {
    color: var(--white);
    opacity: 0.5;
    pointer-events: all;

    &:hover {
      cursor: no-drop;
    }
  }
  &:hover {
    &::before {
      opacity: 0.3;
    }
  }
`

export const TgButton = styled(Button)`
  border: none;
  background-image: linear-gradient(180deg, #2AABEE 0%, #229ED9 99.22%);
  color: var(--white);
  border-radius: 28px;
  position: relative;
  z-index: 1;
  font-weight: 700;
  width: 100%;
  max-width: 220px;

  &:hover {
    &::before {
      opacity: 0.3;
    }
  }
`

export const GoogleButton = styled(Button)`
  border: 1px solid #200e321a;
  background-image: linear-gradient(0deg, #ececec 0%, var(--white) fff 66.15%);
  color: #212339;
`

export const AddNewCourseButton = styled(SimpleButton)({
  backgroundImage: "linear-gradient(180deg, #B8E09A 0%, #75CBAB 100%)",
  color: "var(--white)",
  borderRadius: "28px",
  marginLeft: "16px",
  padding: "9px 20px",

  "& p": {
    margin: 0,
    display: "flex",
    alignItems: "center",
    fontWeight: "700",
    "& span": {
      marginRight: "5px",
      fontSize: "21px",
    },
  },
})

export const DuplicateCourseButton = styled(SimpleButton)({
  border: "1px solid rgba(32, 14, 50, 0.2)",
  background: "var(--white)",
  color: "#414562",
  borderRadius: "28px",
  marginLeft: "16px",
  padding: "12px 20px",
  fontWeight: 500,

  "& p": {
    margin: 0,
    display: "flex",
    alignItems: "center",
    fontWeight: "500",
    "& span": {
      marginRight: "5px",
      fontSize: "21px",
    },
  },
})

export default Button
