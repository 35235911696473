import classNames from "classnames"
import styled, { css } from "styled-components"
import { HeadingProps } from "."

const Heading = styled.h1<HeadingProps>`
  font-style: normal;
  font-family: var(--font);
  margin-bottom: 0px;
  line-height: normal;
  font-weight: 700;
  font-size: 36px;

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
  ${(props) =>
    props.fontWeight &&
    css`
      font-weight: ${props.fontWeight};
    `}
  ${(props) =>
    props.ml &&
    css`
      margin-left: ${props.ml};
    `}
  ${(props) =>
    props.mr &&
    css`
      margin-right: ${props.mr};
    `}
  ${(props) =>
    props.mt &&
    css`
      margin-top: ${props.mt};
    `}
  ${(props) =>
    props.my &&
    css`
      margin-top: ${props.my};
      margin-bottom: ${props.my};
    `}
  ${(props) =>
    props.mx &&
    css`
      margin-left: ${props.mx};
      margin-right: ${props.mx};
    `}
  ${(props) =>
    props.mb &&
    css`
      margin-bottom: ${props.mb};
    `}
  ${(props) =>
    props.pl &&
    css`
      padding-left: ${props.pl};
    `}
  ${(props) =>
    props.pr &&
    css`
      padding-right: ${props.pr};
    `}
  ${(props) =>
    props.pt &&
    css`
      padding-top: ${props.pt};
    `}
  ${(props) =>
    props.py &&
    css`
      padding-top: ${props.py};
      padding-bottom: ${props.py};
    `}
  ${(props) =>
    props.px &&
    css`
      padding-left: ${props.px};
      padding-right: ${props.px};
    `}
  ${(props) =>
    props.mb &&
    css`
      margin-bottom: ${props.mb};
    `}
  ${(props) =>
    props.pb &&
    css`
      padding-bottom: ${props.pb};
    `}
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `}
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
  ${(props) =>
    props.textTransform &&
    css`
      text-transform: ${props.textTransform};
    `}
  ${(props) =>
    props.fontStyle &&
    css`
      font-style: ${props.fontStyle};
    `}
  ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}
  ${(props) =>
    props.opacity &&
    css`
      opacity: ${props.opacity};
    `}


  ${(props) =>
    props.position &&
    css`
      position: ${props.position};
    `}
  ${(props) =>
    props.b &&
    css`
      bottom: ${props.b};
    `}
  ${(props) =>
    props.t &&
    css`
      top: ${props.t};
    `}
  ${(props) =>
    props.r &&
    css`
      right: ${props.r};
    `}
  ${(props) =>
    props.l &&
    css`
      left: ${props.l};
    `}


  &.numberOfLine {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
`

/**
 * Font size: `36px`
 * Font weight: `700`
 */
const H1 = ({
  children,
  color = "var(--black)",
  numberOfLine,
  className,
  ...props
}: HeadingProps) => {
  return (
    <Heading
      color={color}
      className={classNames(numberOfLine ? "numberOfLine" : "", className)}
      style={{
        lineClamp: numberOfLine,
        WebkitLineClamp: numberOfLine,
      }}
      {...props}>
      {children}
    </Heading>
  )
}

export default H1
