import { ComponentType, DetailedHTMLProps, HTMLAttributes } from "react"
import styled, { css } from "styled-components"

type Globals =
  | "-moz-initial"
  | "inherit"
  | "initial"
  | "revert"
  | "revert-layer"
  | "unset"

export interface BoxProps<T> extends DetailedHTMLProps<HTMLAttributes<T>, T> {
  m?: Globals | "auto" | (string & {})
  ml?: Globals | "auto" | (string & {})
  mr?: Globals | "auto" | (string & {})
  mt?: Globals | "auto" | (string & {})
  mb?: Globals | "auto" | (string & {})
  mx?: Globals | "auto" | (string & {})
  my?: Globals | "auto" | (string & {})

  p?: Globals | "auto" | (string & {})
  pl?: Globals | "auto" | (string & {})
  pt?: Globals | "auto" | (string & {})
  pb?: Globals | "auto" | (string & {})
  pr?: Globals | "auto" | (string & {})
  px?: Globals | "auto" | (string & {})
  py?: Globals | "auto" | (string & {})

  w?:
    | Globals
    | "-moz-fit-content"
    | "-moz-max-content"
    | "-moz-min-content"
    | "-webkit-fit-content"
    | "-webkit-max-content"
    | "auto"
    | "fit-content"
    | "intrinsic"
    | "max-content"
    | "min-content"
    | "min-intrinsic"
    | (string & {})
  maxWidth?:
    | Globals
    | "-moz-fit-content"
    | "-moz-max-content"
    | "-moz-min-content"
    | "-webkit-fit-content"
    | "-webkit-max-content"
    | "auto"
    | "fit-content"
    | "intrinsic"
    | "max-content"
    | "min-content"
    | "min-intrinsic"
    | (string & {})
  h?:
    | Globals
    | "-moz-fit-content"
    | "-moz-max-content"
    | "-moz-min-content"
    | "-webkit-fit-content"
    | "-webkit-max-content"
    | "auto"
    | "fit-content"
    | "intrinsic"
    | "max-content"
    | "min-content"
    | "min-intrinsic"
    | (string & {})

  border?: string
  borderBottom?: string
  borderTop?: string
  borderRight?: string
  borderLeft?: string

  flex?: string | number
  flexGrow?: string | number
  flexWrap?: string
  flexDirection?: string
  justifyContent?: string
  alignItems?: string
  alignContent?: string

  fontSize?: string
  fontFamily?: string
  wordWrap?: string

  opacity?: Globals | (string & {}) | (number & {})
  display?: string
  overflow?: string
  backgroundColor?: string
  borderRadius?: string
  htmlFor?: string

  position?: string
  t?: string | number
  l?: string | number
  b?: string | number
  r?: string | number
}

function style<T>(Elem: keyof JSX.IntrinsicElements | ComponentType<BoxProps<T>>) {
  return styled(Elem)<BoxProps<T>>`
    /* 
  
  MARGINS  
  
  */
    ${(props) =>
      props.m &&
      css`
        margin: ${props.m};
      `}
    ${(props) =>
      props.ml &&
      css`
        margin-left: ${props.ml};
      `}
  ${(props) =>
      props.mr &&
      css`
        margin-right: ${props.mr};
      `}
  ${(props) =>
      props.mt &&
      css`
        margin-top: ${props.mt};
      `}
  ${(props) =>
      props.mb &&
      css`
        margin-bottom: ${props.mb};
      `}
  ${(props) =>
      props.mx &&
      css`
        margin-right: ${props.mx};
        margin-left: ${props.mx};
      `}
  ${(props) =>
      props.my &&
      css`
        margin-top: ${props.my};
        margin-bottom: ${props.my};
      `}
  
  /* 
  
  PADDINGS  
  
  */
  ${(props) =>
      props.p &&
      css`
        padding: ${props.p};
      `}  
  ${(props) =>
      props.pr &&
      css`
        padding-right: ${props.pr};
      `}  
  ${(props) =>
      props.pt &&
      css`
        padding-top: ${props.pt};
      `}  
  ${(props) =>
      props.pb &&
      css`
        padding-bottom: ${props.pb};
      `}
  ${(props) =>
      props.pl &&
      css`
        padding-left: ${props.pl};
      `}
  ${(props) =>
      props.px &&
      css`
        padding-right: ${props.px};
        padding-left: ${props.px};
      `}
  ${(props) =>
      props.py &&
      css`
        padding-top: ${props.py};
        padding-bottom: ${props.py};
      `}
  
  /* 
  
  SIZING  
  
  */
  ${(props) =>
      props.w &&
      css`
        width: ${props.w};
      `}
  ${(props) =>
      props.maxWidth &&
      css`
        max-width: ${props.maxWidth};
      `}
  ${(props) =>
      props.h &&
      css`
        height: ${props.h};
      `}
  
  /* 
  
  BORDER  
  
  */
  ${(props) =>
      props.borderRadius &&
      css`
        border-radius: ${props.borderRadius};
      `}
  ${(props) =>
      props.border &&
      css`
        border: ${props.border};
      `}
  ${(props) =>
      props.borderBottom &&
      css`
        border-bottom: ${props.borderBottom};
      `}
  ${(props) =>
      props.borderTop &&
      css`
        border-top: ${props.borderTop};
      `}
  ${(props) =>
      props.borderLeft &&
      css`
        border-left: ${props.borderLeft};
      `}
  ${(props) =>
      props.borderRight &&
      css`
        border-right: ${props.borderRight};
      `}
  
  /* 
  
  FLEX  
  
  */
  ${(props) =>
      props.flex &&
      css`
        flex: ${props.flex};
      `}
  ${(props) =>
      props.flexGrow &&
      css`
        flex-grow: ${props.flexGrow};
      `}
  ${(props) =>
      props.flexWrap &&
      css`
        flex-wrap: ${props.flexWrap};
      `}
  ${(props) =>
      props.flexDirection &&
      css`
        flex-direction: ${props.flexDirection};
      `}
  ${(props) =>
      props.justifyContent &&
      css`
        justify-content: ${props.justifyContent};
      `}
  ${(props) =>
      props.alignContent &&
      css`
        align-content: ${props.alignContent};
      `}
  ${(props) =>
      props.alignItems &&
      css`
        align-items: ${props.alignItems};
      `}
  
  /* 
  
  BOX STYLE: COLOR, BACKGROUND, DISPLAY 
  
  */
  ${(props) =>
      props.backgroundColor &&
      css`
        background-color: ${props.backgroundColor};
      `}
  ${(props) =>
      props.display &&
      css`
        display: ${props.display};
      `}
  ${(props) =>
      props.opacity &&
      css`
        opacity: ${props.opacity};
      `}
  ${(props) =>
      props.color &&
      css`
        color: ${props.color};
      `}
  ${(props) =>
      props.overflow &&
      css`
        overflow: ${props.overflow};
      `}
  
  /* 
  
  POSITION
  
  */
  ${(props) =>
      props.position &&
      css`
        position: ${props.position};
      `}
  ${(props) =>
      props.t &&
      css`
        top: ${props.t};
      `}
  ${(props) =>
      props.b &&
      css`
        bottom: ${props.b};
      `}
  ${(props) =>
      props.r &&
      css`
        right: ${props.r};
      `}
  ${(props) =>
      props.l &&
      css`
        left: ${props.l};
      `}
  /* 
  
  FONT
  
  */
  ${(props) =>
      props.fontFamily &&
      css`
        font-font-family: ${props.fontFamily};
      `}
  ${(props) =>
      props.fontSize &&
      css`
        font-size: ${props.fontSize};
      `}
  ${(props) =>
      props.wordWrap &&
      css`
        word-wrap: ${props.wordWrap};
      `}
  `
}

const InlineBox = style<HTMLDivElement>("div")

const InlineLabel = style<HTMLLabelElement>("label")

const InlineSpan = style<HTMLSpanElement>("span")

export default Object.assign(InlineBox, {
  Label: InlineLabel,
  Span: InlineSpan,
})
