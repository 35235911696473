const plans = {
  pro: {
    price: {
      annualy: 1_490_000, // UZS
      monthly: 149_000, // UZS
    },
  },
  free: {
    price: {
      annual: 0,
      monthly: 0,
    },
  },
}
export default plans
