import { Link, useLocation, useNavigate } from "react-router-dom"
import { CircularProgress, Grid, IconButton, InputAdornment } from "@mui/material"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import styled from "styled-components"
import classNames from "classnames"

import Row from "~/components/common/Row"
import useAppSelector from "~/hooks/useAppSelector"
import useAppDispatch from "~/hooks/useAppDispatch"
import AUTH_API from "~/api/auth"
import Button from "~/components/common/Button"
import GoogleOAuth from "~/components/GoogleOAuth"
import Loader from "~/components/Loader"
import InlineBox from "~/components/common/InlineBox"
import login from "~/context/reducers/auth/login"
import { H2 } from "~/components/common/Headings"
import { P3, P5 } from "~/components/common/Paragraph"
import { EyeCloseIcon, EyeOpenIcon, LogoIcon } from "~/assets/svg"
import { TextField } from "~/components/common/TextField"
import { authenticate } from "~/context/slices/auth"
import { setUser } from "~/context/slices/user"
import { EMAIL_PATTERN } from "~/utils/validation/validateEmail"
import {
  makeMainCard,
  setAllCards,
  setAutoRenewal,
  setCurrentPlan,
} from "~/context/slices/premium"
import {
  CLASSROOM_CREATE,
  FORGOT_PASSWD,
  SIGN_IN,
  SIGN_UP,
} from "~/constants/routes"

import img_ru from "~/assets/imgs/login_ru.jpg"
import img_en from "~/assets/imgs/login_en.jpg"
import img_uz from "~/assets/imgs/login_uz.jpg"
import showError from "~/utils/toast/showError"
import AUTHOR_API from "~/api/author"

export const Logo = styled.h1`
  margin-top: 50px;
`

export const Stick = styled.div`
  width: 75px;
  height: 1px;
  display: inline-block;
  background-color: #212339;
  opacity: 0.15;
`

export const Footer = styled.footer`
  margin-top: 30px;
  margin-bottom: 30px;
`

type Inputs = {
  email: string
  password: string
}

const SignIn = () => {
  const redirectUri = window.location.origin + SIGN_IN

  const navigate = useNavigate()
  const auth = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()
  const location = useLocation()

  const [isPasswordVisible, setIsPasswordVisible] = useState(true)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const {
    t,
    i18n: { language },
  } = useTranslation() as any

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const res = await dispatch(
        login({ email: data.email, password: data.password }),
      )

      if (res.meta.requestStatus === "fulfilled") {
        if (res.payload.ok === -1 && res.payload.reason === "noDomain") {
          localStorage.setItem("@email", data.email)
          navigate("/otp", {
            state: res.payload,
            replace: true,
          })
          return
        }

        const res2 = await AUTHOR_API.getPro()

        if (res2.data.author.wallets?.length) {
          dispatch(
            setAllCards(
              res2.data.author.wallets.map((wallet: any) => ({
                cardNumber: wallet.number,
                cardExpired: wallet.expire,
                cardToken: wallet.token,
              })),
            ),
          )

          dispatch(makeMainCard(res2.data.author.mainWallet || 0))
        }
        if (res2.data.author.proPlan?.createdAt) {
          const now = Date.now()
          const createdAt = new Date(res2.data.author.proPlan?.createdAt!).getTime()
          const expiresIn = new Date(res2.data.author.proPlan?.expiresIn!).getTime()

          if (now > createdAt && now < expiresIn) {
            dispatch(setCurrentPlan("pro"))
            dispatch(setAutoRenewal(res2.data.author.proPlan?.autoRenewal!))
          }
        }

        dispatch(setUser({ ...res.payload.me, proPlan: res2.data.author.proPlan }))

        const me = res.payload.me
        const token = res.payload.token

        if (me.wallets?.length) {
          dispatch(
            setAllCards(
              me.wallets.map((wallet: any) => ({
                cardNumber: wallet.number,
                cardExpired: wallet.expire,
                cardToken: wallet.token,
              })),
            ),
          )

          dispatch(makeMainCard(me.mainWallet || 0))
        }
        if (me.proPlan?.createdAt) {
          const now = Date.now()
          const createdAt = new Date(me.proPlan?.createdAt).getTime()
          const expiresIn = new Date(me.proPlan?.expiresIn).getTime()

          if (now > createdAt && now < expiresIn) {
            dispatch(setCurrentPlan("pro"))
            dispatch(setAutoRenewal(me.proPlan?.autoRenewal))
          }
        }

        dispatch(setUser({ ...me, proPlan: me.proPlan }))
        dispatch(authenticate({ token, me }))
      } else if (res.meta.requestStatus === "rejected") {
        showError(res.payload, {
          position: "top-left",
        })
      }
    } catch (err: any) {
      showError(err)
    }
  }

  const toggleVisibilityPassword = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    if (!queryParams.get("code")) return
    const code = queryParams.get("code")
    const scope = queryParams.get("scope")
    const authuser = queryParams.get("authuser")

    if (code && scope && authuser) {
      AUTH_API.googleOAuth(code, redirectUri)
        .then((res) => {
          localStorage.setItem("@actionToken", res.data.actionToken)
          const me = res.data.me
          const token = res.data.token

          if (res.data.actionToken) {
            navigate(CLASSROOM_CREATE, {
              replace: true,
            })
            return
          }
          if (token) {
            if (me?.email && me?.domain && me?.name) {
              dispatch(setUser(me))
              dispatch(authenticate({ token: token, me }))
            }

            if (me.wallets?.length) {
              dispatch(
                setAllCards(
                  me.wallets.map((wallet: any) => ({
                    cardNumber: wallet.number,
                    cardExpired: wallet.expire,
                    cardToken: wallet.token,
                  })),
                ),
              )

              dispatch(makeMainCard(me.mainWallet || 0))
            }

            if (me.proPlan?.createdAt) {
              const now = Date.now()
              const createdAt = new Date(me.proPlan?.createdAt).getTime()
              const expiresIn = new Date(me.proPlan?.expiresIn).getTime()

              if (now > createdAt && now < expiresIn) {
                dispatch(setCurrentPlan("pro"))
                dispatch(setAutoRenewal(me.proPlan?.autoRenewal))
              }
            }
            localStorage.setItem("@email", me.email)
            localStorage.setItem("@token", token)

            navigate(CLASSROOM_CREATE, {
              replace: true,
            })
          }
        })
        .catch((err) => {
          showError(err)
          navigate(SIGN_IN, {
            replace: true,
          })
        })
    } else {
      navigate(SIGN_IN, {
        replace: true,
      })
    }
  }, [location])

  if (new URLSearchParams(window.location.search).get("code")) {
    return <Loader />
  }

  return (
    <Grid className={"ls-auth__main"} container>
      <Grid
        xs={12}
        sm={7}
        md={6}
        item
        className={classNames("ls-auth__leftContainer")}>
        <Logo>
          <LogoIcon />
        </Logo>

        <InlineBox w="400px" maxWidth="95%" mb="20px">
          <H2 textAlign="center" mb="20px">
            {t("auth")}
          </H2>
          <P3 textAlign="center" mb="30px">
            {t("sign_in_subtitle")}
          </P3>
          <form onSubmit={handleSubmit(onSubmit)} className="mb-20">
            <InlineBox mb="20px">
              <TextField
                {...register("email", {
                  required: true,
                  pattern: EMAIL_PATTERN,
                })}
                variant="outlined"
                label={t("email", { count: 1 })}
                error={!!errors.email?.type}
                id="email"
                type="email"
                fullWidth
                placeholder="name@example.com"
              />
            </InlineBox>{" "}
            <InlineBox mb="20px">
              <TextField
                variant="outlined"
                label={t("password")}
                error={!!errors.password?.type}
                helperText={errors.password?.message}
                id="password"
                type={isPasswordVisible ? "password" : "text"}
                {...register("password", {
                  required: true,
                  minLength: 8,
                })}
                sx={{
                  pr: 0,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleVisibilityPassword}>
                        {isPasswordVisible ? (
                          <EyeOpenIcon
                            width="20"
                            height="15"
                            fill="var(--new-grey)"
                          />
                        ) : (
                          <EyeCloseIcon
                            width="20"
                            height="15"
                            fill="var(--new-grey)"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                autoComplete="off"
                fullWidth
                placeholder="******"
              />
            </InlineBox>
            <Button
              className={"w-100 brsr-extra"}
              disabled={auth.loading}
              type="submit">
              {auth.loading ? (
                <CircularProgress
                  size={20}
                  sx={{
                    color: "var(--white)",
                  }}
                />
              ) : (
                t("submit")
              )}
            </Button>
          </form>
          <P3 textAlign="center" mb="25px">
            {t("dont_have_account")}?{" "}
            <Link to={SIGN_UP}>
              <>{t("sign_up_for_free")}</>
            </Link>
          </P3>
          <Row justify="center" align="center" mb="20px">
            <Stick />
            <InlineBox mr="5px" />
            <P3>{t("or")}</P3>
            <InlineBox mr="5px" />
            <Stick />
          </Row>

          <GoogleOAuth className={"w-100 brsr-extra"} />
        </InlineBox>

        <Footer>
          <P3 textAlign="center" mb="25px">
            {t("forgot_password")}?{" "}
            <Link to={FORGOT_PASSWD}>
              <>{t("restore")}</>
            </Link>
          </P3>
          <P5 textAlign="center">
            © 2022{" "}
            <strong>
              <Link to="/">Lessenger</Link>
            </strong>{" "}
            {t("all_rights_reserved")}
          </P5>
        </Footer>
      </Grid>
      <Grid
        xs={12}
        sm={5}
        md={6}
        className={"ls-auth__rightContainer"}
        sx={{
          backgroundImage: `url(${
            language === "en" ? img_en : language === "uz" ? img_uz : img_ru
          })`,
          backgroundColor: "#45c6e6",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat"
        }}
        item
      />
    </Grid>
  )
}

export default SignIn
