import classNames from "classnames"
import styled, { css } from "styled-components"

interface Props {
  numberOfLine?: number
  className?: string
  color?: string
  opacity?: number

  position?: string
  b?: string | number
  t?: string | number
  r?: string | number
  l?: string | number

  fontWeight?: string
  fontStyle?: string
  fontSize?: string
  lineHeight?: string

  ml?: string | number
  mr?: string | number
  mt?: string | number
  mb?: string | number
  mx?: string | number
  my?: string | number

  px?: string | number
  py?: string | number
  pr?: string | number
  pl?: string | number
  pt?: string | number
  pb?: string | number

  flex?: number
  maxWidth?: string | number

  textTransform?: string
  textAlign?: string

  children?: React.ReactNode | React.ReactNode[] | any
}

const Typography = styled.p<Props>`
  font-style: normal;
  font-family: var(--font);
  margin-bottom: 0px;

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
  ${(props) =>
    props.lineHeight &&
    css`
      line-height: ${props.lineHeight};
    `}
  ${(props) =>
    props.fontWeight &&
    css`
      font-weight: ${props.fontWeight};
    `}
  ${(props) =>
    props.ml &&
    css`
      margin-left: ${props.ml};
    `}
  ${(props) =>
    props.mr &&
    css`
      margin-right: ${props.mr};
    `}
  ${(props) =>
    props.mt &&
    css`
      margin-top: ${props.mt};
    `}
  ${(props) =>
    props.my &&
    css`
      margin-top: ${props.my};
      margin-bottom: ${props.my};
    `}
  ${(props) =>
    props.mx &&
    css`
      margin-left: ${props.mx};
      margin-right: ${props.mx};
    `}
  ${(props) =>
    props.mb &&
    css`
      margin-bottom: ${props.mb};
    `}
  ${(props) =>
    props.pl &&
    css`
      padding-left: ${props.pl};
    `}
  ${(props) =>
    props.pr &&
    css`
      padding-right: ${props.pr};
    `}
  ${(props) =>
    props.pt &&
    css`
      padding-top: ${props.pt};
    `}
  ${(props) =>
    props.py &&
    css`
      padding-top: ${props.py};
      padding-bottom: ${props.py};
    `}
  ${(props) =>
    props.px &&
    css`
      padding-left: ${props.px};
      padding-right: ${props.px};
    `}
  ${(props) =>
    props.pb &&
    css`
      padding-bottom: ${props.pb};
    `}
  ${(props) =>
    props.mb &&
    css`
      margin-bottom: ${props.mb};
    `}
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `}
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
  ${(props) =>
    props.textTransform &&
    css`
      text-transform: ${props.textTransform};
    `}
  ${(props) =>
    props.fontStyle &&
    css`
      font-style: ${props.fontStyle};
    `}
  ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}
  ${(props) =>
    props.opacity &&
    css`
      opacity: ${props.opacity};
    `}


  ${(props) =>
    props.position &&
    css`
      position: ${props.position};
    `}
  ${(props) =>
    props.b &&
    css`
      bottom: ${props.b};
    `}
  ${(props) =>
    props.t &&
    css`
      top: ${props.t};
    `}
  ${(props) =>
    props.r &&
    css`
      right: ${props.r};
    `}
  ${(props) =>
    props.l &&
    css`
      left: ${props.l};
    `}


  &.numberOfLine {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
  &.p1 {
    font-size: 18px;
  }
  &.p2 {
    font-size: 16px;
  }
  &.p3 {
    font-size: 14px;
  }
  &.p4 {
    font-size: 13px;
  }
  &.p5 {
    font-size: 12px;
  }
  &.p6 {
    font-size: 11px;
  }
  &.p7 {
    font-size: 10px;
  }
`

const Paragraph = ({
  children,
  color = "var(--black)",
  fontWeight = "400",
  className,
  ...props
}: Props) => {
  return (
    <Typography
      color={color}
      fontWeight={fontWeight}
      className={classNames(className)}
      {...props}>
      {children}
    </Typography>
  )
}

/**
 * Font size: `18px`
 */
export const P1 = ({
  children,
  color = "var(--black)",
  numberOfLine,
  fontWeight = "400",
  className,
  ...props
}: Props) => {
  return (
    <Typography
      fontWeight={fontWeight}
      color={color}
      className={classNames("p1", numberOfLine ? "numberOfLine" : "", className)}
      {...props}>
      {children}
    </Typography>
  )
}

/**
 * Font size: `16px`
 */
export const P2 = ({
  children,
  color = "var(--black)",
  numberOfLine,
  fontWeight = "400",
  className,
  ...props
}: Props) => {
  return (
    <Typography
      fontWeight={fontWeight}
      color={color}
      className={classNames("p2", numberOfLine ? "numberOfLine" : "", className)}
      style={{
        lineClamp: numberOfLine,
        WebkitLineClamp: numberOfLine,
      }}
      {...props}>
      {children}
    </Typography>
  )
}

/**
 * Font size: `14px`
 */
export const P3 = ({
  children,
  color = "var(--black)",
  fontWeight = "400",
  numberOfLine,
  className,
  ...props
}: Props) => {
  return (
    <Typography
      fontWeight={fontWeight}
      color={color}
      className={classNames("p3", numberOfLine ? "numberOfLine" : "", className)}
      style={{
        lineClamp: numberOfLine,
        WebkitLineClamp: numberOfLine,
      }}
      {...props}>
      {children}
    </Typography>
  )
}

/**
 * Font size: `13px`
 */
export const P4 = ({
  children,
  color = "var(--black)",
  className,
  fontWeight = "400",
  numberOfLine,
  ...props
}: Props) => {
  return (
    <Typography
      color={color}
      fontWeight={fontWeight}
      className={classNames("p4", numberOfLine ? "numberOfLine" : "", className)}
      {...props}>
      {children}
    </Typography>
  )
}

/**
 * Font size: `12px`
 */
export const P5 = ({
  children,
  color = "var(--black)",
  className,
  fontWeight = "400",
  numberOfLine,
  ...props
}: Props) => {
  return (
    <Typography
      color={color}
      fontWeight={fontWeight}
      className={classNames("p5", numberOfLine ? "numberOfLine" : "", className)}
      {...props}>
      {children}
    </Typography>
  )
}

/**
 * Font size: `11px`
 */
export const P6 = ({
  children,
  color = "var(--black)",
  numberOfLine,
  fontWeight = "400",
  className,
  ...props
}: Props) => {
  return (
    <Typography
      color={color}
      fontWeight={fontWeight}
      className={classNames("p6", numberOfLine ? "numberOfLine" : "", className)}
      {...props}>
      {children}
    </Typography>
  )
}

/**
 * Font size: `10px`
 */
export const P7 = ({
  children,
  color = "var(--black)",
  numberOfLine,
  className,
  fontWeight = "400",
  ...props
}: Props) => {
  return (
    <Typography
      fontWeight={fontWeight}
      color={color}
      className={classNames("p7", numberOfLine ? "numberOfLine" : "", className)}
      {...props}>
      {children}
    </Typography>
  )
}

export default Paragraph
