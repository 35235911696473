import React, { useEffect } from "react"

import PageWithDrawer from "~/components/PageWithDrawer"
import { H2, H3, H6 } from "~/components/common/Headings"
import Row from "~/components/common/Row"

import { useTranslation } from "react-i18next"
import { CancelIcon, CheckIcon, CrownIcon, QuestionMarkIcon } from "~/assets/svg"
import { P3 } from "~/components/common/Paragraph"
import InlineBox from "~/components/common/InlineBox"
import useAppDispatch from "~/hooks/useAppDispatch"
import useAppSelector from "~/hooks/useAppSelector"
import { setBillType } from "~/context/slices/premium"
import SelectPaymentMethod from "~/components/screen-based/Pro/SelectPaymentMethod"
import SuccessSub from "~/components/screen-based/Pro/SuccessSub"
import { Link } from "react-router-dom"
import { SUBSCRIPTIONS } from "~/constants/routes"
import Tooltip from "~/components/common/Tooltip"
import AddCard from "~/components/screen-based/Subscriptions/AddCard"
import plans from "~/constants/plans"
import { BillSwitch } from "~/components/common/Switch"


const Pro = () => {
  const { t } = useTranslation() as any
  const dispatch = useAppDispatch()
  const { bill, currentPlan, paymentMethod, cards, mainWallet } = useAppSelector(
    (s) => s.premium,
  )

  const [modal, setModal] = React.useState({
    selectPaymentMethod: false,
    successSub: false,
    addCard: false,
  })

  const handleOpenModal = (value: keyof typeof modal) => {
    setModal((prev) => ({ ...prev, [value]: true }))
  }

  const handleCloseAllModal = () => {
    setModal({
      selectPaymentMethod: false,
      successSub: false,
      addCard: false,
    })
  }

  // useEffect(() => {
  //   if (paymentMethod) {
  //     if (cards.length && (mainWallet || mainWallet === 0)) {
  //     } else {
  //       handleOpenModal("addCard")
  //     }
  //   }
  // }, [modal.addCard])

  return (
    <PageWithDrawer>
      <Row align="center" mb={"30px"} justify="space-between">
        <H2>{t("improve_plan")}</H2>

        <Row align="center">
          <H6
            mr="16px"
            color={bill !== "monthly" ? "var(--new-grey)" : "var(--dark)"}>
            {t("billed_monthly")}
          </H6>
          <BillSwitch
            value={bill === "annualy"}
            onChange={(e) => {
              if (e.target.checked) {
                dispatch(setBillType("annualy"))
              } else {
                dispatch(setBillType("monthly"))
              }
            }}
          />
          <H6
            ml="16px"
            color={bill !== "annualy" ? "var(--new-grey)" : "var(--dark)"}>
            {t("billed_annually")}
          </H6>
        </Row>
      </Row>

      <div className="pro__container">
        <div className="pro__row">
          <div className="pro__sell pro__sell-head pro_sell-empty"></div>
          <div className="pro__sell pro__sell-head">
            <InlineBox flexDirection="column" display="flex" alignItems={"center"}>
              <h1>Free</h1>
              <H2>
                {plans.free.price.monthly} {t("uzs")}
              </H2>
            </InlineBox>
          </div>
          <div className="pro__sell pro__sell-head">
            <InlineBox flexDirection="column" display="flex" alignItems={"center"}>
              <h1>
                <span>
                  <CrownIcon width="26" height="26" />
                </span>{" "}
                Pro
              </h1>
              <H2>
                {bill === "annualy" ? (
                  <>
                    <s>{plans.pro.price.monthly * 12}</s>{" "}
                    {t("per_year", {
                      price: `${plans.pro.price.annualy} ${t("uzs")}`,
                    })}
                  </>
                ) : (
                  `${plans.pro.price.monthly} ${t("uzs")}`
                )}
              </H2>
            </InlineBox>
          </div>
        </div>
        <div className="pro__row">
          <div className="pro__sell pro__sell-title">
            <Tooltip
              title={
                <React.Fragment>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla,
                  dolorem magni! Sequi nihil voluptatum, eos aspernatur possimus
                  culpa velit hic eaque, rem itaque nulla corporis accusamus enim
                  cupiditate quibusdam beatae.
                </React.Fragment>
              }>
              <div className="d-flex">
                <H3>{t("courses")}</H3>
                <span className="ml-5 d-flex align-self-center">
                  <QuestionMarkIcon width="15px" height="15px" />
                </span>
              </div>
            </Tooltip>
          </div>
          <div className="pro__sell pro__sell">
            <P3>1</P3>
          </div>
          <div className="pro__sell pro__sell">
            <P3 color="var(--dark)">{t("unlimited")}</P3>
          </div>
        </div>
        <div className="pro__row">
          <div className="pro__sell pro__sell pro__sell-title">
            <H3>{t("users")}</H3>
            <span className="ml-5 d-flex align-self-center">
              <QuestionMarkIcon width="15px" height="15px" />
            </span>
          </div>
          <div className="pro__sell pro__sell">
            <P3>100 {t("users")}</P3>
          </div>
          <div className="pro__sell pro__sell">
            <P3 color="var(--dark)">{t("unlimited")}</P3>
          </div>
        </div>
        <div className="pro__row">
          <div className="pro__sell pro__sell pro__sell-title">
            <H3>{t("analytics")} </H3>
            <span className="ml-5 d-flex align-self-center">
              <QuestionMarkIcon width="15px" height="15px" />
            </span>
          </div>
          <div className="pro__sell">
            <CancelIcon />
          </div>
          <div className="pro__sell">
            <CheckIcon />
          </div>
        </div>

        <div className="pro__row">
          <div className="pro__sell pro__sell pro__sell-title">
            <H3>{t("invite_by_email")} </H3>
            <span className="ml-5 d-flex align-self-center">
              <QuestionMarkIcon width="15px" height="15px" />
            </span>
          </div>
          <div className="pro__sell">
            <CancelIcon />
          </div>
          <div className="pro__sell">
            <CheckIcon />
          </div>
        </div>
        <div className="pro__row">
          <div className="pro__sell pro__sell pro__sell-title">
            <H3>{t("marketplace")} </H3>
            <span className="ml-5 d-flex align-self-center">
              <QuestionMarkIcon width="15px" height="15px" />
            </span>
          </div>
          <div className="pro__sell">
            <CancelIcon />
          </div>
          <div className="pro__sell">
            <CheckIcon />
          </div>
        </div>
        <div className="pro__row">
          <div className="pro__sell pro__sell-footer pro__sell-title">
            <div className="d-flex">
              <H3>{t("author_on_marketplace")} </H3>
              <span className="ml-5 d-flex align-self-center">
                <QuestionMarkIcon width="15px" height="15px" />
              </span>
            </div>
          </div>
          <div className="pro__sell pro__sell-footer pro__sell pro__sell-title">
            <InlineBox
              w="100%"
              flexDirection="column"
              display="flex"
              alignItems={"center"}>
              <CancelIcon />

              {currentPlan === "trial" ? (
                <button className="pro__btn" disabled>
                  {t("your_current_plan")}
                </button>
              ) : null}
            </InlineBox>
          </div>
          <div className="pro__sell pro__sell-footer pro__sell ">
            <InlineBox
              w="100%"
              flexDirection="column"
              display="flex"
              alignItems={"center"}>
              <CheckIcon />

              {currentPlan === "pro" ? (
                <>
                  <button className="pro__btn mb-20" disabled>
                    {t("your_current_plan")}
                  </button>
                  <Link to={SUBSCRIPTIONS} className="text-button grey">
                    {t("manage_subscriptions")}
                  </Link>
                </>
              ) : (
                <button
                  onClick={() => handleOpenModal("selectPaymentMethod")}
                  className="pro__btn">
                  {t("buy_plan", {
                    plan:
                      bill === "annualy"
                        ? t("annual").toLowerCase()
                        : t("monthly").toLowerCase(),
                    price:
                      bill === "annualy"
                        ? plans.pro.price.annualy
                        : plans.pro.price.monthly,
                  })}{" "}
                  {t("uzs")}
                </button>
              )}
            </InlineBox>
          </div>
        </div>
      </div>

      <SelectPaymentMethod
        open={modal.selectPaymentMethod}
        handleClose={handleCloseAllModal}
      />
      <SuccessSub open={modal.successSub} handleClose={handleCloseAllModal} />
      <AddCard pay open={modal.addCard} handleClose={handleCloseAllModal} />
    </PageWithDrawer>
  )
}

export default Pro
