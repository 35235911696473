import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: {
  sse: string
} = {
  sse: "",
}

export const sseSlice = createSlice({
  name: "sse",
  initialState: initialState,
  reducers: {
    sendData: (state, action: PayloadAction<any>) => {
      state.sse = action.payload
    },
    clearSSEData: (state) => {
      state.sse = ""
    },
  },
})

export const { sendData, clearSSEData } = sseSlice.actions

export default sseSlice.reducer
