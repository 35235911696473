import { useState } from "react"
import { useTranslation } from "react-i18next"
import { CircularProgress, Grid } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"

import AUTH_API from "~/api/auth"
import classNames from "classnames"
import Button from "~/components/common/Button"
import { H2 } from "~/components/common/Headings"
import { P3, P5 } from "~/components/common/Paragraph"
import { LogoIcon } from "~/assets/svg"
import { TextField } from "~/components/common/TextField"
import InlineBox from "~/components/common/InlineBox"
import { SubmitHandler, useForm } from "react-hook-form"
import { EMAIL_PATTERN } from "~/utils/validation/validateEmail"
import { Footer, Logo } from "../SignIn"
import { FORGOT_PASSWD_OTP } from "~/constants/routes"

import img_ru from "~/assets/imgs/login_ru.jpg"
import img_en from "~/assets/imgs/login_en.jpg"
import img_uz from "~/assets/imgs/login_uz.jpg"
import showError from "~/utils/toast/showError"

type Inputs = {
  email: string
}

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>()

  const {
    t,
    i18n: { language },
  } = useTranslation() as any

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setLoading(true)
      const res = await AUTH_API.forgotPassword({ email: data.email })
      if (res.data.ok === 1) {
        const date = new Date()
        date.setMinutes(date.getMinutes() + 5)
        localStorage.setItem("@email", data.email)
        localStorage.setItem("@expiresIn", JSON.stringify(date.getTime()))
        navigate(FORGOT_PASSWD_OTP, {
          state: {
            email: data.email,
            resetToken: res.data.resetToken,
          },
        })
        setLoading(false)
      }
    } catch (err: any) {
      setLoading(false)
      showError(err)
    }
  }

  return (
    <Grid className={"ls-auth__main"} container>
      <Grid
        xs={12}
        sm={7}
        md={6}
        item
        className={classNames("ls-auth__leftContainer")}>
        <Logo>
          <LogoIcon />
        </Logo>

        <InlineBox w="350px" maxWidth="95%" mb="20px">
          <H2 textAlign="center" mb="20px">
            {t("reset_password")}
          </H2>
          <P3 textAlign="center" mb="30px">
            {t("reset_password_subtitle")}
          </P3>

          <form onSubmit={handleSubmit(onSubmit)} className="mb-20">
            <InlineBox mb="20px">
              <TextField
                {...register("email", {
                  required: true,
                  pattern: EMAIL_PATTERN,
                })}
                variant="outlined"
                label={t("email", { count: 1 })}
                error={!!errors.email?.type}
                id="email"
                type="email"
                fullWidth
                placeholder="name@example.com"
              />
            </InlineBox>{" "}
            <Button className={"w-100 brsr-extra"} disabled={loading} type="submit">
              {loading ? (
                <CircularProgress
                  size={20}
                  sx={{
                    color: "var(--white)",
                  }}
                />
              ) : (
                t("submit")
              )}
            </Button>
          </form>
        </InlineBox>

        <Footer>
          <P5 textAlign="center">
            © 2022{" "}
            <strong>
              <Link to="/">Lessenger</Link>
            </strong>{" "}
            {t("all_rights_reserved")}
          </P5>
        </Footer>
      </Grid>
      <Grid
        xs={12}
        sm={5}
        md={6}
        className={"ls-auth__rightContainer"}
        sx={{
          backgroundImage: `url(${
            language === "en" ? img_en : language === "uz" ? img_uz : img_ru
          })`,
        }}
        item
      />
    </Grid>
  )
}

export default ForgotPassword
