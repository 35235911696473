import AUTHOR_API from "~/api/author"
import { setCurrentPlan } from "~/context/slices/premium"
import { setProPlan } from "~/context/slices/user"
import useAppDispatch from "./useAppDispatch"
import useAppSelector from "./useAppSelector"

export default () => {
  const dispatch = useAppDispatch()
  const { bill, autoRenewal } = useAppSelector((state) => state.premium)

  const purchaseSubscription = async (onSuccess?: () => void) => {
    const res = await AUTHOR_API.purchaseSubscription(
      bill === "annualy",
      autoRenewal || false,
    )
    const date = new Date()
    const createdAt = new Date(date)
    date.setUTCMonth(date.getUTCMinutes() + (bill === "annualy" ? 12 : 1))

    if (res.data.ok === 1) {
      dispatch(
        setProPlan({
          autoRenewal: autoRenewal,
          createdAt: createdAt.toISOString(),
          expiresIn: date.toISOString(),
          isActive: true,
          level: "pro",
        }),
      )
      dispatch(setCurrentPlan("pro"))
      if (onSuccess) {
        onSuccess()
      }
    }
  }

  return { purchaseSubscription }
}
