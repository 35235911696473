import axios from "~/utils/axios";
import { AxiosRequestConfig } from "axios";

const ENDPOINT = "/dash/authors/wallets";

export const addWallet = async (
  body: { paymeToken: string; cardTitle: string; mainWallet?: number },
  config?: AxiosRequestConfig<any>
) => {
  const res = await axios.post(`${ENDPOINT}`, body, config);
  return res;
};

export const deleteWallet = async (walletToken: string, mainWallet: number, config?: AxiosRequestConfig<any>) => {
  const res = await axios.delete(`${ENDPOINT}`, { ...config, data: { walletToken, mainWallet } });
  return res;
};

export const changeMainWallet = async (mainWallet: number, config?: AxiosRequestConfig<any>) => {
  const res = await axios.patch(`${ENDPOINT}/main`, { mainWallet }, config);
  return res;
};
