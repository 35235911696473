import { hideConfirm, showConfirm } from "~/context/slices/confirm"
import useAppDispatch from "./useAppDispatch"

let resolveCallback: (resolve: boolean) => void
function useConfirm() {
  const dispatch = useAppDispatch()

  const onConfirm = () => {
    closeConfirm()
    resolveCallback(true)
  }

  const onCancel = () => {
    closeConfirm()
    resolveCallback(false)
  }
  const confirm = (title: string, content: string) => {
    dispatch(
      showConfirm({
        content,
        title,
      })
    )

    return new Promise((res, rej) => {
      resolveCallback = res
    })
  }

  const closeConfirm = () => {
    dispatch(hideConfirm())
  }

  return { confirm, onConfirm, onCancel }
}

export default useConfirm
