import Row from "~/components/common/Row"
import { styled } from "@mui/material"

export const Codes = styled(Row)({
  justifyContent: "space-between",
})

export const Code = styled("input")({
  width: "40px",
  height: "40px",
  display: "flex",
  borderRadius: "8px",
  marginRight: "12px",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
  border: "1px solid var(--grey-2)",

  "&.error": {
    borderColor: "var(--toastify-color-error)",
  },

  "&.focused": {
    borderColor: "var(--primary)",
    boxShadow: "0 0 0 2px #ccf8fe",
  },
})
