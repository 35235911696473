import React, { useState } from "react"
import Chart from "react-apexcharts"
import { useTranslation } from "react-i18next"
import { en, ru, uz } from "./localization"

interface DonutChartProps {
  active: number
  completed: number
  dropouts: number
}

const DonutChart = ({ active, completed, dropouts }: DonutChartProps) => {
  const { t, i18n } = useTranslation() as any
  const [options, setOptions] = useState<ApexCharts.ApexOptions>({
    chart: {
      id: "donut-chart",
      type: "donut",
      locales: [en, ru, uz],
      defaultLocale: i18n.language.split(/-|_/)[0] || "ru",
      animations: {
        dynamicAnimation: {
          enabled: true,
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    dataLabels: {
      formatter: (val: number) => `${val.toString().slice(0, 4)}%`,
      style: {
        fontFamily: "var(--font)",
        fontWeight: "700",
        fontSize: "14px",
      },
      dropShadow: {
        enabled: false,
      },
    },
    plotOptions: {
      pie: {
        customScale: 1,
        donut: {
          size: "50",
          labels: {
            show: true,
            name: {
              fontFamily: "var(--font)",
            },
            value: {
              fontFamily: "var(--font)",
              fontWeight: "bold",
              formatter: (val) => {
                return `${val}`
              },
            },
          },
        },
        expandOnClick: false,
      },
    },

    legend: {
      fontFamily: "var(--font)",
      position: "bottom",
    },
    colors: ["#00E396", "#008FFB", "#c500fb"],
    labels: [t("completed_verb"), t("dropped_out"), t("active")],
  })
  const [series, setSeries] = useState([completed, dropouts, active])

  React.useEffect(() => {
    if (completed !== undefined && active !== undefined && dropouts !== undefined) {
      setSeries([completed, dropouts, active])
    }
  }, [active, completed, dropouts])

  if (
    series[0] === undefined ||
    series[1] === undefined ||
    series[2] === undefined
  ) {
    return null
  }

  return (
    <div id="donutChart" style={{ height: "100%", overflow: "hidden" }}>
      <Chart options={options} series={series} type="donut" height={"100%"} />
    </div>
  )
}

export default DonutChart
