import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ICourse, ICourseDefault } from "~/types/course"

type ICourseSlice = {
  id: ICourse["id"]
  activationMessage: ICourse["activationMessage"]
  author: ICourse["author"]
  category: ICourse["category"]
  description: ICourse["description"]
  lang: ICourse["lang"]
  logo: ICourse["logo"]
  tags: ICourse["tags"]
  title: ICourse["title"]
  authorLink: ICourse["authorLink"]
  status: ICourse["status"]
  initTags?: ICourse["tags"]
  initActivationMessage?: ICourse["activationMessage"]
  initDescription?: ICourse["description"]
  promo?: ICourse["promo"]
}

export const initialState: ICourseSlice = {
  id: ICourseDefault["id"],
  activationMessage: ICourseDefault["activationMessage"],
  author: ICourseDefault["author"],
  category: ICourseDefault["category"],
  description: ICourseDefault["description"],
  initDescription: ICourseDefault["description"],
  lang: ICourseDefault["lang"],
  logo: ICourseDefault["logo"],
  tags: ICourseDefault["tags"],
  initTags: ICourseDefault["tags"],
  title: ICourseDefault["title"],
  authorLink: ICourseDefault["authorLink"],
  status: ICourseDefault["status"],
  initActivationMessage: ICourseDefault["activationMessage"],
  promo: ICourseDefault["promo"]
}

export const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    // COURSE
    setCourse: (state, { payload }: PayloadAction<ICourseSlice>) => {
      state.id = payload.id
      state.activationMessage = payload.activationMessage
      state.author = payload.author
      state.category = payload.category
      state.description = payload.description
      state.lang = payload.lang
      state.logo = payload.logo
      state.tags = payload.tags
      state.title = payload.title
      state.authorLink = payload.authorLink
      state.status = payload.status
      state.initTags = payload.tags
      state.initDescription = payload.description
      state.initActivationMessage = payload.activationMessage
      state.promo = payload.promo
    },
    setTitle: (state, action: PayloadAction<ICourseSlice["title"]>) => {
      state.title = action.payload
    },
    setTags: (state, action: PayloadAction<ICourseSlice["tags"]>) => {
      state.tags = action.payload
    },
    setShareWithContact: (
      state,
      action: PayloadAction<ICourseSlice["authorLink"]>,
    ) => {
      state.authorLink = action.payload
    },
    setActivationMessage: (
      state,
      action: PayloadAction<ICourseSlice["activationMessage"]>,
    ) => {
      state.activationMessage = action.payload
    },
    setInitActivationMessage: (
      state,
      action: PayloadAction<ICourseSlice["activationMessage"]>,
    ) => {
      state.initActivationMessage = action.payload
    },
    setInitDescription: (
      state,
      action: PayloadAction<ICourseSlice["description"]>,
    ) => {
      state.initDescription = action.payload
    },
    setLanguage: (state, action: PayloadAction<ICourseSlice["lang"]>) => {
      state.lang = action.payload
    },
    setCategory: (state, action: PayloadAction<ICourseSlice["category"]>) => {
      state.category = action.payload
    },
    setDescription: (state, action: PayloadAction<ICourseSlice["description"]>) => {
      state.description = action.payload
    },
    setLogo: (state, action: PayloadAction<ICourseSlice["logo"]>) => {
      state.logo = action.payload
    },
    setStatus: (state, action: PayloadAction<ICourseSlice["status"]>) => {
      state.status = action.payload
    },
    setPromo: (state, action: PayloadAction<ICourseSlice["promo"]>) => {
      state.promo = action.payload
    },
  },
})

export const courseActions = courseSlice.actions

export default courseSlice.reducer
