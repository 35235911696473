import axios from "~/utils/axios"
import { AxiosRequestConfig } from "axios"

const ENDPOINT = "/dash/subscriptions"

// export const createSubscription = async (config?: AxiosRequestConfig<any>) => {
//   const res = await axios.post(ENDPOINT, config);
//   return res;
// };

export const getSubscriptions = async (config?: AxiosRequestConfig<any>) => {
  const res = await axios.get(ENDPOINT, config)
  return res
}

export const getMyInvities = async (config?: AxiosRequestConfig<any>) => {
  const res = await axios.get(`${ENDPOINT}/invities`, config)
  return res
}

export const getDash = async (config?: AxiosRequestConfig<any>) => {
  const res = await axios.get(`/dash`, config)
  return res
}

export const deleteSubscription = async (
  subId: string,
  config?: AxiosRequestConfig<any>,
) => {
  const res = await axios.delete(`${ENDPOINT}/${subId}`, config)
  return res
}

export const bulkUpload = async (
  emails: string[],
  courseId: string,
  invite = false,
  config?: AxiosRequestConfig<any>,
) => {
  const body = invite
    ? {
        emails,
        courseId,
        invite,
      }
    : {
        emails,
        courseId,
      }
  const res = await axios.post(`${ENDPOINT}`, body, config)
  return res
}

export const invite = async (subIds: string[], config?: AxiosRequestConfig<any>) => {
  const res = await axios.post(`${ENDPOINT}/invite`, { subIds }, config)
  return res
}
