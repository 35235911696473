import { IAuthor } from "~/types/author"

export interface UserInitialState extends IAuthor {
  surname: string
}

const userInitialState: UserInitialState = {
  avatar: "",
  id: "",
  domain: "",
  paymentMethods: {},
  wallets: [],

  name: "Guest",
  email: "",
  intro: "",
  surname: "",
  links: undefined,
  rating: 0,
  tagLine: "",
}

export default userInitialState
