import { IAuthor } from "~/types/author"
import axios from "~/utils/axios"
import { AxiosRequestConfig } from "axios"

const ENDPOINT = "dash/authors"

const getProfile = async (config?: AxiosRequestConfig<any>) => {
  const res = await axios.get(`${ENDPOINT}/profile`, config)
  return res
}

const getPaymentMethods = async (config?: AxiosRequestConfig<any>) => {
  const res = await axios.get(`${ENDPOINT}/payments`, config)
  return res
}

const updatePaymentMethods = async (
  body: { type: any; creds: any },
  config?: AxiosRequestConfig<any>,
) => {
  const res = await axios.patch(`${ENDPOINT}/payments`, body, config)
  return res
}

const removePaymentMethods = async (
  type: string,
  config?: AxiosRequestConfig<any>,
) => {
  const res = await axios.delete(`${ENDPOINT}/payments/${type}`, config)
  return res
}

const updateProfile = async (
  author: Partial<IAuthor>,
  config?: AxiosRequestConfig<any>,
) => {
  const res = await axios.patch(`${ENDPOINT}`, author, config)
  return res
}

const getPro = async (config?: AxiosRequestConfig<any>) => {
  const res = await axios.get<{
    author: {
      id: IAuthor["id"]
      mainWallet: IAuthor["mainWallet"]
      wallets: IAuthor["wallets"]
      proPlan: IAuthor["proPlan"]
    }
  }>(`${ENDPOINT}/subscription`, config)
  return res
}

const purchaseSubscription = async (
  annual: boolean,
  autoRenewal: boolean,
  config?: AxiosRequestConfig<any>,
) => {
  const res = await axios.post(
    `${ENDPOINT}/subscription/purchase`,
    { autoRenewal, annual },
    config,
  )
  return res
}

const AUTHOR_API = {
  getProfile,
  getPro,
  updateProfile,
  purchaseSubscription,
  updatePaymentMethods,
  removePaymentMethods,
  getPaymentMethods,
}

export default AUTHOR_API
