import CircularProgress from "@mui/material/CircularProgress"
import styled from "styled-components"

const LoaderContainer = styled.div`
  background-color: var(--white);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;

  &.p-fixed {
    position: fixed;
    z-index: 9999999;
  }
`

const Loader = ({ className = "" }: { className?: string }) => {
  return (
    <LoaderContainer className={className}>
      <CircularProgress size={60} />
    </LoaderContainer>
  )
}

export default Loader
