import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { H2 } from "~/components/common/Headings"
import { getDash } from "~/api/subscriptions"
import { logout } from "~/context/slices/auth"
import PageWithDrawer from "~/components/PageWithDrawer"
import Charts from "~/components/screen-based/Dashboard/Charts"
import useAppDispatch from "~/hooks/useAppDispatch"
import InlineBox from "~/components/common/InlineBox"
import MainMetrics from "~/components/screen-based/Dashboard/MainMetrics"
import LastRegistrations from "~/components/screen-based/Dashboard/LastRegistrations"
import { setInitialPremium } from "~/context/slices/premium"
import showError from "~/utils/toast/showError"

const Dashboard = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation() as any

  const [registrations, setRegistrations] = useState<number>()
  const [coursesLength, setCoursesLength] = useState(0)
  const [salesAmount, setSalesAmount] = useState(0)
  const [statuses, setStatuses] = useState<any>({})
  const [lastRegs, setLastRegs] = useState([])
  const [regsSales, setRegsSales] = useState([])

  React.useEffect(() => {
    let controller = new AbortController()
    getDash({
      signal: controller?.signal,
    })
      .then((res) => {
        setRegistrations(res.data.registrations)
        setCoursesLength(res.data.courses)
        setSalesAmount(res.data.salesAmount)
        setLastRegs(res.data.lastRegs)
        setStatuses(res.data.statuses)
        setRegsSales(res.data.diagramm)
      })
      .catch((err) => {
        if (err.message === "canceled") return
        showError(err)
        if (err.response?.status === 401) {
          dispatch(logout(true))
          dispatch(setInitialPremium())
        }
      })

    return () => controller?.abort()
  }, [])

  return (
    <PageWithDrawer>
      <H2 mb="20px">{t("your_metrics")}</H2>
      <InlineBox>
        <MainMetrics
          registrations={registrations || 0}
          coursesLength={coursesLength}
          salesAmount={salesAmount}
        />
        <Charts
          regsSales={regsSales}
          tgCount={registrations}
          statuses={statuses}
          whCount={0}
        />
        <LastRegistrations data={lastRegs} />
      </InlineBox>
    </PageWithDrawer>
  )
}

export default Dashboard
