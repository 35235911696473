import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: {
  list: { value: string; name: string }[]
} = {
  list: [],
}

export const catsSlice = createSlice({
  name: "cats",
  initialState: initialState,
  reducers: {
    setAllCategories: (state, action: PayloadAction<{ value: string; name: string }[]>) => {
      state.list = action.payload
    },
  },
})

export const { setAllCategories } = catsSlice.actions

export default catsSlice.reducer
