import { PlusSimpleIcon, RegistrationsIcon, SalesIcon } from "~/assets/svg"
import { SimpleButton } from "~/components/common/Button"
import { H1, H5, H7 } from "~/components/common/Headings"
import { P5 } from "~/components/common/Paragraph"
import Row from "~/components/common/Row"
import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import InlineBox from "~/components/common/InlineBox"
import { Link } from "react-router-dom"
import { COURSE_CREATE } from "~/constants/routes"
import styled from "styled-components"

const Container = styled(InlineBox)`
  background-color: var(--white);
  padding: 20px 33px;
  border-radius: 8px;
  box-shadow: 0px 5px 10px #f1f2fa;
  margin-bottom: 16px;
`

interface MainMetricsProps {
  coursesLength: number
  registrations: number
  salesAmount: number
}

const MainMetrics = ({
  coursesLength,
  registrations,
  salesAmount,
}: MainMetricsProps) => {
  const { t } = useTranslation() as any

  return (
    <Container>
      <Grid
        container
        rowSpacing={2}
        className="metrics"
        justifyContent="space-between"
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item className={"item"}>
          <Row align="center" mb="3px">
            <RegistrationsIcon />
            <H5 ml="4px" color="#9BA0AD">
              {t("registrations_per", { period: t("year").toLowerCase() })}
            </H5>
          </Row>
          <Row align="center">
            <H1>{registrations}</H1>
            <InlineBox ml="10px">
              <H7 color="var(--blue)">{registrations}</H7>
              <P5>{t("for_the_last_year").toLowerCase()}</P5>
            </InlineBox>
          </Row>
        </Grid>
        <Grid item className={"item"}>
          <Row align="center" mb="3px">
            <SalesIcon />
            <H5 ml="4px" color="#9BA0AD">
              {t("sales_per", { period: t("year").toLowerCase() })}
            </H5>
          </Row>
          <Row align="center">
            <H1>{salesAmount || 0} UZS</H1>
            <InlineBox ml="10px">
              <H7 color="var(--blue)">{salesAmount || 0} UZS</H7>
              <P5>{t("for_the_last_year").toLowerCase()}</P5>
            </InlineBox>
          </Row>
        </Grid>
        <Grid item className={"item"}>
          <Row align="center" mb="3px">
            <SalesIcon />
            <H5 ml="4px" color="#9BA0AD">
              {t("course_views", { period: t("year").toLowerCase() })}
            </H5>
          </Row>
          <Row align="center">
            <H1>0</H1>
            <InlineBox ml="10px">
              <H7 color="#9BA0AD">0</H7>
              <P5>{t("for_the_last_year").toLowerCase()}</P5>
            </InlineBox>
          </Row>
        </Grid>
        <Grid item className={"item"}>
          <Row align="center" mb="3px">
            <RegistrationsIcon />
            <H5 ml="4px" color="#9BA0AD">
              {t("number_of_courses")}
            </H5>
          </Row>
          <Row align="center">
            <H1>{coursesLength}</H1>
            <InlineBox ml="10px">
              <Link to={COURSE_CREATE}>
                <SimpleButton prefixIcon>
                  <PlusSimpleIcon />
                  {t("create_course")}
                </SimpleButton>
              </Link>
            </InlineBox>
          </Row>
        </Grid>
      </Grid>
    </Container>
  )
}

export default MainMetrics
