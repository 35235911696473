import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IAuthor } from "~/types/author"
import userInitialState, { UserInitialState } from "../initialStates/user"

const initialState = userInitialState

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      const user = JSON.parse(localStorage.getItem("@user") || "{}")
      localStorage.setItem(
        "@user",
        JSON.stringify({
          ...user,
          name: action.payload,
        }),
      )
      state.name = action.payload
    },
    setSurname: (state, action: PayloadAction<string>) => {
      const user = JSON.parse(localStorage.getItem("@user") || "{}")
      localStorage.setItem(
        "@user",
        JSON.stringify({
          ...user,
          name: `${user.name ? `${user.name} ` : ""}${action.payload}`,
        }),
      )
      state.surname = action.payload
    },
    setAvatar: (state, action: PayloadAction<string>) => {
      const user = JSON.parse(localStorage.getItem("@user") || "{}")
      localStorage.setItem(
        "@user",
        JSON.stringify({
          ...user,
          avatar: action.payload,
        }),
      )
      state.avatar = action.payload
    },
    setId: (state, action: PayloadAction<string>) => {
      const user = JSON.parse(localStorage.getItem("@user") || "{}")
      localStorage.setItem(
        "@user",
        JSON.stringify({
          ...user,
          id: action.payload,
        }),
      )
      state.id = action.payload
    },
    setPaymentMethods: (state, action: PayloadAction<IAuthor["paymentMethods"]>) => {
      const user = JSON.parse(localStorage.getItem("@user") || "{}")
      localStorage.setItem(
        "@user",
        JSON.stringify({
          ...user,
          paymentMethods: action.payload,
        }),
      )
      state.paymentMethods = action.payload
    },
    setProPlan: (state, action: PayloadAction<IAuthor["proPlan"]>) => {
      const user = JSON.parse(localStorage.getItem("@user") || "{}")

      state.proPlan = action.payload

      localStorage.setItem(
        "@user",
        JSON.stringify({
          ...user,
          proPlan: action.payload,
        }),
      )
    },
    setOffer: (state, action: PayloadAction<IAuthor["offer"]>) => {
      const user = JSON.parse(localStorage.getItem("@user") || "{}")
      localStorage.setItem(
        "@user",
        JSON.stringify({
          ...user,
          offer: action.payload,
        }),
      )
      state.offer = action.payload
    },
    setUser: (state, action: PayloadAction<UserInitialState>) => {
      state.avatar = action.payload.avatar
      state.domain = action.payload.domain
      state.id = action.payload.id
      state.name = action.payload.name
      state.paymentMethods = action.payload.paymentMethods
      state.email = action.payload.email
      state.intro = action.payload.intro
      state.tgGroupId = action.payload.tgGroupId
      state.surname = action.payload.surname
      state.wallets = action.payload.wallets
      state.mainWallet = action.payload.mainWallet
      state.proPlan = action.payload.proPlan
      state.offer = action.payload.offer
      state.tagLine = action.payload.tagLine
      state.rating = action.payload.rating
      state.links = action.payload.links
      state.tgId = action.payload.tgId
      state.tgNumber = action.payload.tgNumber

      localStorage.setItem("@user", JSON.stringify(action.payload))
    },
    clearUser: (state) => {
      state.avatar = ""
      state.domain = ""
      state.mainWallet = undefined
      state.paymentMethods = {}
      state.email = ""
      state.id = ""
      state.offer = undefined
      state.name = "Guest"
      state.wallets = []
      state.links = undefined
      state.tgGroupId = undefined
      state.rating = 0
      state.tagLine = ""
      state.intro = ""
      state.tgId = undefined
      state.tgNumber = undefined

      localStorage.removeItem("@user")
    },
  },
})

export const {
  setAvatar,
  setProPlan,
  setUser,
  setSurname,
  setPaymentMethods,
  setName,
  setId,
  clearUser,
} = userSlice.actions

export default userSlice.reducer
